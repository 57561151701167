html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

.infinite-scroll-component__outerdiv {
  overflow-y: hidden !important;
}

button {
  cursor: pointer;
  border: 0;
  background-color: unset;
}

button[disabled],
button.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.FakeInput {
  input{
    padding-right: 40px;
    font-weight: 700;
    font-size: 18px;
  }
  &>:last-child{
    color: transparent!important;
    z-index: -1;
  }
}

[contenteditable="true"] {
  white-space: nowrap;
  overflow: hidden;
} 
[contenteditable="true"] br {
  display:none;

}
[contenteditable="true"] * {
  display:inline;
  white-space:nowrap;
}

/* Make Scrollable */
[contenteditable="true"] {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
[contenteditable="true"]::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
} 