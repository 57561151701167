@import "../mixins/mixins";

.breezy-home {
  .distribution-wrap {
    background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 30.17%);

    &:before {
      @extend %before-after;
      top: -4px;
      height: 136px;
      width: 100%;
      background: linear-gradient(0deg, #13072c 50%, rgba(19, 7, 44, 0) 100%);
    }
  }
}

.distribution {
  display: flex;
  position: relative;
  gap: 2px;
  padding-left: 41px;

  &-title {
    height: 40px;
    width: 122px;
    background: #270637;
    border-radius: 0px 0px 12px 12px;
    border: 2px solid #591b78;
    border-top: 0;
    position: absolute;
    top: 44px;
    left: -40px;
    transform: rotate(-90deg);
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    text-shadow:
      -1px -1px 0 #d32ce6,
      1px -1px 0 #d32ce6,
      -1px 1px 0 #d32ce6,
      1px 1px 0 #d32ce6,
      0px 0px 18px #d32ce6;
    text-transform: uppercase;
    @include flex(false, center, center, false);
  }

  & > :first-child {
    border-radius: 12px 0 0 12px;

    .distribution-item-bg {
      border-radius: 12px 0 0 12px;
    }

    &:before {
      border-radius: 12px 0 0 12px;
    }
  }

  & > :last-child {
    border-radius: 0 12px 12px 0;

    .distribution-item-bg {
      border-radius: 0 12px 12px 0;
    }

    &:before {
      border-radius: 0 12px 12px 0;
    }
  }

  &-wrap {
    padding: 4px 4px 0 4px;
    position: relative;
    z-index: 5;
    height: 126px;
    scrollbar-width: none;

    & + .cases.cases-all {
      margin-top: -20px;
      padding-top: 18px;
    }
    @media (max-width: 1200px) {
      overflow: hidden;
      overflow-x: auto;
      height: 144px;
    }

    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px;
      background: transparent;
    }

    &:before {
      @extend %before-after;
      width: 100%;
      height: 136px;
      background: linear-gradient(0deg, #13072c 50%, rgba(19, 7, 44, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      top: 0;
      left: 0;
    }
  }

  &-item {
    display: flex;
    width: 25%;
    cursor: pointer;
    height: 122px;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    background: #17023a;

    @media (max-width: 1200px) {
      min-width: 271px;
    }

    &[data-rar="b0c3d9"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(176, 195, 217, 0.2) 0%, rgba(176, 195, 217, 0) 100%), #17023a;

        &:before {
          background-color: #b0c3d9;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(176, 195, 217, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(176, 195, 217, 0.4) 0%, rgba(176, 195, 217, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(176, 195, 217, 0.4);
        }
      }
    }

    &[data-rar="4b69ff"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(75, 105, 255, 0.2) 0%, rgba(75, 105, 255, 0) 100%), #17023a;

        &:before {
          background-color: #4b69ff;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(75, 105, 255, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(75, 105, 255, 0.4) 0%, rgba(75, 105, 255, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(75, 105, 255, 0.4);
        }
      }
    }

    &[data-rar="5e98d9"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(94, 152, 217, 0.2) 0%, rgba(94, 152, 217, 0) 100%), #17023a;

        &:before {
          background-color: #5e98d9;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(94, 152, 217, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(94, 152, 217, 0.4) 0%, rgba(94, 152, 217, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(94, 152, 217, 0.4);
        }
      }
    }

    &[data-rar="8847ff"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(136, 71, 255, 0.2) 0%, rgba(136, 71, 255, 0) 100%), #17023a;

        &:before {
          background-color: #8847ff;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(136, 71, 255, 0.4) 0%, rgba(136, 71, 255, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.4);
        }
      }
    }

    &[data-rar="d32ce6"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(211, 44, 230, 0.2) 0%, rgba(211, 44, 230, 0) 100%), #17023a;

        &:before {
          background-color: #d32ce6;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(211, 44, 230, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(211, 44, 230, 0.4) 0%, rgba(211, 44, 230, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(211, 44, 230, 0.4);
        }
      }
    }

    &[data-rar="eb4b4b"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(235, 75, 75, 0.2) 0%, rgba(235, 75, 75, 0) 100%), #17023a;

        &:before {
          background-color: #eb4b4b;
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(235, 75, 75, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(235, 75, 75, 0.4) 0%, rgba(235, 75, 75, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(235, 75, 75, 0.4);
        }
      }
    }

    &[data-rar="ffc600"],
    &[data-rar="ffc700"],
    &[data-rar="ffd700"] {
      .distribution-item-bg {
        background: linear-gradient(360deg, rgba(255, 198, 0, 0.2) 0%, rgba(255, 198, 0, 0) 100%), #17023a;

        &:before {
          background-color: #ffc600;
        }

        svg {
          path {
            fill: #ffc600;
          }
        }
      }

      &:before {
        box-shadow: inset 0 0 0 2px rgba(255, 198, 0, 0.16);
      }

      &:hover {
        .distribution-item-bg {
          background: linear-gradient(360deg, rgba(255, 198, 0, 0.4) 0%, rgba(255, 198, 0, 0) 100%), #17023a;
        }

        &:before {
          box-shadow: inset 0 0 0 2px rgba(255, 198, 0, 0.4);
        }
      }
    }

    &:before {
      @extend %before-after;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: box-shadow 0.3s;
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.16);
      z-index: 2;
    }

    &:hover {
      .distribution-item-img {
        img {
          margin-top: -10px;
        }
      }

      .distribution-item-controls-timer {
        color: #f7dbff;
      }

      .distribution-item-controls-counter {
        color: #f7dbff;

        .icon {
          color: #f7dbff;
        }
      }
    }
  }

  &-item-content {
    width: 100%;
    max-width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.68);

    @media (max-width: 1366px) {
      max-width: calc(100% - 98px);
    }
  }

  &-item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @include before() {
      -webkit-mask: url(../images/bg-live-item-white-2.svg);
      background: url(../images/bg-live-item-white22.svg);
      width: 100%;
      height: 100%;
      opacity: 1;
      -webkit-mask-position: 50% 50%;
    }
  }

  &-item-img {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;

    img {
      width: 190px;
      height: 140px;
      object-fit: contain;
      filter: drop-shadow(-2px 8px 8px rgba(0, 0, 0, 0.65));

      @media (max-width: 1600px) {
        width: 140px;
        height: 140px;
      }
      @media (max-width: 1366px) {
        width: 98px;
        height: 98px;
      }
    }
  }

  &-item-num {
    padding-bottom: 8px;
    margin-bottom: 8px;
    position: relative;

    @include before() {
      width: 220px;
      height: 2px;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.2;
      top: calc(100% - 1px);
      left: 0;

      @media (max-width: 992px) {
        width: 123px;
      }
    }
  }

  &-item-name {
    position: relative;
  }

  &-item-title {
    font-weight: 700;
    color: #d5b9ff;
    margin-bottom: 8px;
    position: relative;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-item-controls {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.5);
    margin-top: auto;
    white-space: nowrap;

    @media (max-width: 1366px) {
      left: 0;
      white-space: nowrap;
    }

    &-timer,
    &-counter,
    &-part {
      display: flex;
      align-items: center;
      padding: 0px 8px 0px 4px;
      height: 24px;
      background: rgba(19, 7, 44, 0.32);
      backdrop-filter: blur(4px);
      border-radius: 8px;
      position: relative;
      z-index: 2;

      .day {
        margin-right: 4px;
        // color: #fef2c8;
        // -webkit-text-stroke: 1px rgba(235, 75, 75, 0.48);
      }

      .icon {
        // color: #FF2C3B;
        // box-shadow: 0 0 12px 1px #ff2c3b;
        // background: #FEF2C8;
        border-radius: 50%;
        font-size: 17px;
        margin: 0 6px 0 -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        font-size: 20px;
      }
    }

    &-counter {
      .icon {
        background: none;
        border-radius: 0;
        // color: rgba(213, 185, 255, 0.5);
        box-shadow: none;
        margin-left: 0;
        font-size: 16px;
        text-shadow: 0px 0px 8px #ff2c3b;
        // >>> new
        color: #fef2c8;
      }
    }

    &-part {
      background: linear-gradient(90deg, #005700 0%, #089408 100%);
      border: 2px solid #06c50c;
      color: #ffffff;
      font-size: 0;
      padding: 0;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      bottom: 12px;
      right: 12px;

      .icon {
        box-shadow: none;
        background: none;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        margin: 0;
      }
    }
  }

  &-item-controls-cost {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 18px;
    border-radius: 12px;
    letter-spacing: 0.05em;
    padding: 0 6px;
    z-index: 5;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #490475 0%, #380b9d 100%), #c4c4c4;
    box-shadow: 0 0 0 2px #6804a1;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}
