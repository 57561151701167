@import '../mixins/mixins';
.header-menu {
  @include flex(row, center, false, false);
  margin: 0 auto;

  @media(max-width: 1200px) {
    display: none;
  }

  &__item {
    @include flex(column, center, false, false);
    text-decoration: none;
    width: 112px;
    margin: 0 4px;
    position: relative;

    @include before() {
      width: 1px;
      height: 80px;
      background: radial-gradient(50% 50% at 50% 50%, #B0C3D9 0%, rgba(176, 195, 217, 0.0001) 100%);
      mix-blend-mode: normal;
      opacity: 0.2;
      left: auto;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
    @include after() {
      width: 112px;
      height: 112px;
      left: calc(50% - 112px / 2);
      top: calc(50% - 112px / 2 - 12px);
      background: radial-gradient(50% 50% at 50% 50%, rgba(117, 55, 0, 0.48) 0%, rgba(57, 15, 99, 0.0001) 100%);
      mix-blend-mode: normal;
      opacity: 0.4;
    }

    &-icon {
      margin-bottom: 5px;
      height: 32px;
      width: 32px;
      position: relative;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-name {
      color: #ccc;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.325px;
      text-transform: uppercase;
      position: relative;
      z-index: 2;
      transition: all .3s;
      text-align: center;
    }

    &:hover {
      .header-menu__item-name {
        color: #FFDF77;
      }

      &:after {
        opacity: 0.6;
      }

      .header-menu__item-icon {
        svg {
          path {
            fill: #FFDF77;
          }
        }
      }
    }

    &.active {
      .header-menu__item-name {
        color: #D32CE6;
      }

      .header-menu__item-icon {
        position: relative;

        @include before() {
          background: #D32CE6;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          top: -14px;
          left: 50%;
          transform: translateX(-50%);
        }

        svg {
          path {
            fill: #D32CE6;
          }
        }
      }

      &:after {
        background: radial-gradient(50% 100% at 50% 0%, #D32CE6 0%, rgba(29, 5, 56, 0.0001) 100%), linear-gradient(180deg, #1E043A 0%, rgba(30, 4, 58, 0.64) 79%, rgba(29, 5, 56, 0.0001) 100%);
        mix-blend-mode: normal;
        height: 80px;
        opacity: 0.4;
        filter: blur(21.7463px);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.disabled {
      pointer-events: none;

      &:after {
        display: none;
      }

      .header-menu__item-name {
        color: #8847FF;
        opacity: 0.32;
      }

      .header-menu__item-icon {
        svg {
          path {
            fill: rgba(136, 71, 255, 0.32);
          }
        }
      }
    }

    .icon-badge {
      left: -5px;
      position: absolute;
      top: -10px;
    }
  }

  & > :last-child {
    &:before {
      display: none;
    }
  }
}
