@import "../mixins/mixins";

.header__wrap.fixed {
  .mobile-menu-nav {
    height: calc(100vh - 92px);
  }
}

.mobile-menu {
  display: none;
  padding-right: 16px;
  z-index: 6;

  @media (max-width: 1200px) {
    display: flex;
  }

  &.active {
    .mobile-menu-nav {
      @include flex(column, false, false, false);
      opacity: 1;
      visibility: visible;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 99999;
      background: linear-gradient(90deg, rgba(52, 4, 73, 0.0001) 0%, #2e0a53 75%, #250569 100%), #13072c;
      border-left: 2px solid rgba(252, 221, 255, 0.16);
    }
  }

  &-nav {
    display: none;
    opacity: 0;
    visibility: hidden;
    padding-top: 20px;
    height: calc(100vh - 199px);
    overflow-y: auto;
    transition: all 0.3s;
    min-width: 328px;

    .header-profile-user__dropdown-items {
      margin-bottom: 44px;
    }

    .header-profile-user__dropdown-item {
      padding: 0 16px;
    }

    .login-btns {
      margin-left: 0;
      margin-bottom: 44px;

      &__item {
        min-width: 48px;

        &--steam {
          width: 100%;
          padding: 4px;

          span {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .header-search {
      min-height: 48px;
      padding: 0;
      max-width: calc(100% - 32px);
      margin: 0 auto 16px auto;

      @media (max-width: 992px) {
        display: block;
      }

      &__wrap {
        padding: 0 16px;
      }

      input {
        padding: 0;
        font-size: 16px;
      }
      &-drop-wrap {
        max-height: 126px;
      }
    }

    .header__controls {
      min-height: 48px;
      padding: 0 16px;
      margin-bottom: 16px;

      &-lang {
        flex-grow: 1;

        &-dropdown {
          top: auto;
          bottom: 100%;
          width: 100%;
        }
      }
    }

    .soc-footer {
      justify-content: center;

      a {
        width: 50px;
        height: 60px;
        background-size: 60px 60px !important;

        &:before {
          pointer-events: none;
        }
      }
    }
  }

  &-btn {
    width: 48px;
    height: 48px;
    position: relative;
    @include flex(false, center, center, false);
    @include before() {
      width: 64px;
      height: 64px;
      left: calc(50% - 64px / 2);
      top: calc(50% - 64px / 2);
      background: radial-gradient(50% 50% at 50% 50%, rgba(117, 55, 0, 0.48) 0%, rgba(57, 15, 99, 0.0001) 100%);
      mix-blend-mode: normal;
      opacity: 0.6;
      transition: all 0.3s;
    }

    svg {
      position: relative;

      path {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #ffdf77;
        }
      }
    }

    &:active,
    &.active {
      svg {
        path {
          fill: #d32ce6;
        }
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
