.gift-skin {
    position: fixed;
    top: 390px;
    left: 0;
    display: flex;
    align-items: center;
    padding: 28px 90px 28px 6px;
    border-radius: 0px 50px 50px 0px;
    text-decoration: none;
    color: #ffc600;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    text-transform: uppercase;
    transition: all 0.3s;
    z-index: 1000;

    @media (max-width: 767px) {
        padding: 0;
        margin-left: 3px;
        height: 55px;
        width: 55px;
        top: 320px;
        left: 20px;
    }

    &:hover {
        .border {
            background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
            opacity: 0.8;
        }
    }

    &:active {
        .border {
            background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
            opacity: 0.4;
        }
    }

    .gift-skin-bg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: linear-gradient(to left, #77007b 52.45%, #250055 79.77%, #220081 100%);
        border-radius: 0px 50px 50px 0px;
        height: 100%;
        width: 100%;

        @media (max-width: 767px) {
            border-radius: 50%;
        }
    }

    .border {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: all 0.3s;
        border-radius: 0px 50px 50px 0px;
        background: rgb(63, 49, 228);
        background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
        opacity: 0.2;
        pointer-events: none;
        height: calc(100% + 8px);
        width: calc(100% + 4px);
        z-index: -1;

        @media (max-width: 767px) {
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            height: calc(100% + 4px);
            width: calc(100% + 4px);
        }
    }

    .text {
        display: inline-block;
        text-align: center;
        width: 72px;
        z-index: 1;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .gift-wrap {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        height: 80px;
        width: 80px;

        @media (max-width: 767px) {
            right: 0;
            height: 55px;
            width: 55px;
        }

        img {
            position: absolute;
        }
    }
}

.gift-animate,
.gift-bg,
.gift-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gift-shadow {
    z-index: 10;
}

.gift-animate {
    animation: rotating 5s linear infinite;
    transform: rotate(1turn);
    transform-origin: 50% 50%;
    transition: all 0.5s;
}
