@mixin scrollbar($width, $colorTrack, $colorThumb) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background: $colorTrack;
  }
  &::-webkit-scrollbar-thumb {
    background: $colorThumb;
  }
}
