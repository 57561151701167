@import '../mixins/mixins';
.main {
  &.author {
    .header-profile__cost {
      min-width: 281px;
      white-space: nowrap;
      text-decoration: none;

      @media(max-width: 1700px) {
        min-width: auto;
      }
      @media(max-width: 1600px) {
        position: relative;
        padding: 6px 48px 0 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
    .header-profile__cost-val {
      @media(max-width: 1600px) {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #FFC600;
        padding-right: 0;
        padding-bottom: 0;
      }
    }

    .header-profile__cost-game-val {
      @media(max-width: 1600px) {
        margin-top: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #D32CE6;
        height: 16px;
        margin-right: 0;
      }
    }

    .header-profile__btn {
      @media(max-width: 1600px) {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

    
.page-profile-landing {
  .main {
    &.author {
      .header-profile__cost {
        @media(max-width: 1600px) {
          position: relative;
          padding: 0 0 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.header-profile {
  @include flex(false, center, false, false);
  margin-left: 40px;
  cursor: pointer;

  &__cost {
    @include flex(false, center, false, false);
    background: linear-gradient(270deg, rgba(255, 198, 0, 0.12) 0%, rgba(136, 71, 255, 0.12) 100%);
    border-radius: 8px;
    height: 48px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: 16px;
    flex-grow: 1;

    &-val {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #FFC600;
      text-shadow: 0px 2px 0px #D73806;
      padding-right: 20px;
      padding-bottom: 2px;
    }

    &-game-val {
      @include flex(false, center, false, false);
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #D32CE6;
      margin-right: 8px;
    }

    &:hover {
      background: linear-gradient(270deg, rgba(255, 198, 0, 0.24) 0%, rgba(136, 71, 255, 0.24) 100%);

      .header-profile__btn {
        svg {
          path {
            fill: #FFDF77;
          }
        }
      }
    }

    &:active {
      .header-profile__btn {
        svg {
          path {
            fill: #D32CE6;
          }
        }
      }
    }
  }

  &-user {
    min-width: 80px;
    margin-right: 16px;
    position: relative;

    @media(max-width: 1200px) {
      display: none;
    }

    &__header {
      border-radius: 24px 8px 8px 24px;
      cursor: pointer;
      @include flex(row, center, false, false);

      @include before() {
        border: 2px solid transparent;
        border-radius: 24px 8px 8px 24px;
        background: linear-gradient(0deg, rgba(240, 71, 255, 0.08), rgba(136, 71, 255, 0.08)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      &:hover {
        &:before {
          background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
          -webkit-mask: initial;
        }

        .icon {
          color: rgba(230, 155, 255, 1);
        }
      }
    }

    &__ava {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background: rgb(63, 0, 97);
      background: -moz-radial-gradient(center, ellipse cover, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
      background: radial-gradient(ellipse at center, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3f0061', endColorstr='#31004d', GradientType=1);
      padding: 4px;
      border-radius: 50%;
      box-sizing: content-box;
      position: relative;
      @include flex(row, center, false, false);

      img {
        width: 100%;
        min-width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      } 
    }

    .icon {
      color: rgba(230, 155, 255, 0.4);
      transition: all .3ms;
    }

    &__dropdown {
      @include flex(column, false, false, false);
      display: none;
      opacity: 0;
      visibility: visible;
      transition: all .3s;
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      padding: 8px;
      width: 200px;
      background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%), #13072C;
      box-shadow: 0px 20px 28px #13072C, 0px 28px 64px rgba(50, 50, 50, 0.2);
      border-radius: 8px;
      z-index: 101;

      .header-profile {
        margin-left: 0;
        margin-bottom: 4px;

        &__cost {
          min-width: 100% !important;
          position: relative;
          padding: 6px 12px;
          @include flex(column, flex-start, center, false);

          .header-profile__btn {
            position: absolute;
            right: 0;
            top: 0;
          }

          &-val {
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #FFC600;
          }

          &-game-val {
            margin-top: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #D32CE6;
            height: 16px;
          }
        }
      }

      &-item {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.325px;
        text-transform: uppercase;
        color: #ccc;
        text-decoration: none;
        padding: 4px 8px 4px 4px;
        background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.0192) 0%, rgba(57, 15, 99, 4e-06) 100%);
        mix-blend-mode: normal;
        transition: all .3s;
        margin-bottom: 4px;
        position: relative;
        cursor: pointer;
        @include flex(row, center, false, false);
        @include before() {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.19) 0%, rgba(57, 15, 99, 0) 100%);
          mix-blend-mode: normal;
        }

        img.icon-tag {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          @include size(48px, 16px);
        }

        img,
        svg {
          width: 32px;
          height: 32px;
          margin-right: 8px;

          path {
            transition: all .3s;
          }
        }

        span {
          max-width: 85px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:hover {
          color: #FFDF77;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.192) 0%, rgba(57, 15, 99, 4e-05) 100%);

          svg {
            path {
              fill: #FFDF77;
            }
          }
        }

        &.active {
          background: radial-gradient(50% 3200% at 50% 50%, rgba(111, 0, 123, 0.4) 0%, rgba(57, 15, 99, 4e-05) 100%);
          color: #D32CE6;

          svg {
            path {
              fill: #D32CE6;
            }
          }
        }

        &.disabled {
          background: none;
          color: rgba(136, 71, 255, 0.32);
          pointer-events: none;

          svg {
            path {
              fill: rgba(136, 71, 255, 0.32);
            }
          }
        }
      }
    }

    &.active {
      .header-profile-user__header {
        &:before {
          background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
          -webkit-mask: initial;
        }

        .icon {
          transform: rotate(180deg);
          color: #D32CE6;
        }
      }

      .header-profile-user__dropdown {
        display: flex;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__btn {
    margin-left: auto;
  }
}
