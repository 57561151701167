@import '../mixins/mixins';

.login-btns {
  @include flex(false, center, false, false);
  gap: 4px;
  margin-left: auto;
  padding: 0 16px;

  &__item {
    @include flex(row, center, center, nowrap);
    @include size(64px, 64px);
    border-radius: 6px;
    padding: 4px;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #13072C;
    position: relative;
    transition: all .3s;

    @media(max-width: 992px) {
      @include size(48px, 48px);
    }

    svg {
      min-width: 40px;
      width: 40px;
    }

    &--steam {
      background: linear-gradient(180deg, rgba(255, 92, 0, 0.4) 0%, rgba(255, 92, 0, 0) 100%), linear-gradient(0deg, #FFC600, #FFC600), #13072C;
      box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -3px 0px #C88400;
      width: auto;
      padding: 4px 12px 4px 12px;

      span {
        min-width: 116px;
        text-align: center;

        @media(max-width: 992px) {
          min-width: 88px;
        }
      }

      &:hover {
        background: linear-gradient(180deg, rgba(255, 92, 0, 0.4) 0%, rgba(255, 92, 0, 0) 100%), linear-gradient(0deg, #FFD33A, #FFD33A), #13072C;
        box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -2px 0px #C88400;
      }
    }

    &--fb {
      background: linear-gradient(180deg, rgba(20, 0, 255, 0.4) 0%, rgba(97, 0, 255, 0) 100%), linear-gradient(0deg, #717FFF, #717FFF), #13072C;
      box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -3px 0px #3440AE;
      overflow: hidden;

      &:hover {
        background: linear-gradient(180deg, rgba(20, 0, 255, 0.4) 0%, rgba(97, 0, 255, 0) 100%), linear-gradient(0deg, #8894FF, #8894FF), #13072C;
        box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -2px 0px #3440AE;
      }
    }

    &--google {
      background: linear-gradient(180deg, rgba(119, 115, 171, 0.4) 0%, rgba(250, 252, 255, 0) 100%), linear-gradient(0deg, #E4ECF1, #E4ECF1), #13072C;
      box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -3px 0px #97A3A8;
      overflow: hidden;

      &:hover {
        background: linear-gradient(180deg, rgba(119, 115, 171, 0.4) 0%, rgba(250, 252, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #13072C;
        box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -2px 0px #97A3A8;
      }
    }

    &--vk {
      background: linear-gradient(180deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0) 100%), linear-gradient(0deg, #279AFF, #279AFF), #13072C;
      box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -3px 0px #0F49B7;
      overflow: hidden;

      &:hover {
        background: linear-gradient(180deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0) 100%), linear-gradient(0deg, #58B1FF, #58B1FF), #13072C;
        box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 255, 255, 0.4), inset 0px -2px 0px #0F49B7;
      }
    }
  }
}

.header__wrap {
  & > .login-btns {
    .login-btns__item {
      height: 48px;
      width: 48px;

      &--steam {
        width: auto;

        span {
          min-width: 88px;
        }

        @media(max-width: 1440px) {
          padding: 0;
          width: 48px;
        }

        svg {
          @media(max-width: 1440px) {
            margin-right: 0;
          }
        }

        span {
          @media(max-width: 1440px) {
            display: none;
          }
        }
      }
    }
  }
}

.footer {
  .login-btns {
    display: none;

    @media(max-width: 1024px) {
      display: flex;
      justify-content: center;
      padding: 16px 0;
    }
  }

  .open-cases-btns {
    @media(max-width: 1024px) {
      display: none;
    }
  }
}
