// .чё {
//   width: 196px;
//   height: 100px;
//   position: fixed;
//   top: 390px;
//   left: -95px;
//   z-index: 9;
//   display: flex;
//   align-items: center;
//   transition: all 0.5s;
//   text-decoration: none;
// }
// .circle-banner:before {
//   content: '';
//   position: absolute;
//   top: -90px;
//   left: -111px;
//   background: url(../images/bg-circle-btn-3.png) no-repeat 100% 0;
//   display: block;
//   width: 390px;
//   height: 295px;
//   pointer-events: none;
// }
// .circle-banner .text {
//   font-size: 14px;
//   line-height: 16.8px;
//   color: #ffebcf;
//   text-shadow: -1px -1px 0 rgba(223, 118, 64, 0.48),
//     1px -1px 0 rgba(223, 118, 64, 0.48), -1px 1px 0 rgba(223, 118, 64, 0.48),
//     1px 1px 0 rgba(223, 118, 64, 0.48), 0px 0px 24px #d37d2d,
//     0px 0px 3px #d3602d;
//   font-weight: 700;
//   text-transform: uppercase;
//   text-align: center;
//   position: relative;
//   left: 5px;
//   max-width: 90px;
// }
// .circle-banner .icon-circle {
//   background: url(../images/bg-circle-btn-22.svg) no-repeat 0 0;
//   display: block;
//   width: 218px;
//   min-width: 218px;
//   height: 218px;
//   margin-top: 4px;
//   margin-left: 48px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);

//   img {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }
// .circle-banner .icon-circle span {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 80px;
//   height: 80px;
//   margin: -42px 0 0 -41px;
// }
// .circle-banner .icon-circle svg {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 48px;
//   height: 48px;
//   margin: -26px 0 0 -25px;
//   transform: rotate(180deg);
// }
// .circle-banner .icon-circle img {
//   transition: all 0.5s;
//   transform-origin: 50% 50%;
//   transform: rotate(1turn);
//   animation: rotating 5s linear infinite;
//   margin-top: -45px;
//   margin-left: -44px;
//   width: 88px;
//   height: 88px;
// }
// .circle-banner .icon-circle:after {
//   content: '';
//   position: absolute;
//   top: 52px;
//   left: 50%;
//   width: 45px;
//   height: 46px;
//   transform: translateX(-50%);
//   margin: -9px 0 0 0;
//   background: url(../images/bg-circle-btn-4.svg) no-repeat 0 0;
// }
// .circle-banner.stop .icon-circle svg path {
//   animation-play-state: paused;
// }
// @keyframes rotating {
//   0% {
//     -ms-transform: rotate(0deg);
//     -moz-transform: rotate(0deg);
//     -webkit-transform: rotate(0deg);
//     -o-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   to {
//     -ms-transform: rotate(1turn);
//     -moz-transform: rotate(1turn);
//     -webkit-transform: rotate(1turn);
//     -o-transform: rotate(1turn);
//     transform: rotate(1turn);
//   }
// }
// .circle-banner.active,
// .circle-banner:hover {
//   left: 0;
// }

.circle-banner {
  position: fixed;
  top: 390px;
  left: -95px;
  display: flex;
  align-items: center;
  padding: 28px 90px 28px 6px;
  border-radius: 0px 50px 50px 0px;
  text-decoration: none;
  color: #ffc600;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  text-transform: uppercase;
  transition: all 0.3s;
  z-index: 1000;

  &.active {
    left: 0;
  }

  @media (max-width: 767px) {
    padding: 0;
    margin-left: 3px;
    height: 55px;
    width: 55px;
  }

  &:hover {
    .border {
      background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
      opacity: 0.8;
    }
  }

  &:active {
    .border {
      background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
      opacity: 0.4;
    }
  }

  .circle-banner-bg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: linear-gradient(to left, #77007b 52.45%, #250055 79.77%, #220081 100%);
    transition: all 0.3s;
    border-radius: 0px 50px 50px 0px;
    height: 100%;
    width: 100%;

    @media (max-width: 767px) {
      border-radius: 50%;
    }
  }

  .border {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all 0.3s;
    border-radius: 0px 50px 50px 0px;
    background: rgb(63, 49, 228);
    background: linear-gradient(90deg, rgb(63, 49, 228) 60%, rgb(224, 49, 228) 100%);
    opacity: 0.2;
    pointer-events: none;
    height: calc(100% + 8px);
    width: calc(100% + 4px);
    z-index: -1;

    @media (max-width: 767px) {
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
    }
  }

  .text {
    display: inline-block;
    text-align: center;
    width: 72px;
    z-index: 1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .circle-wrap {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    height: 80px;
    width: 80px;

    @media (max-width: 767px) {
      right: 0;
      height: 55px;
      width: 55px;
    }

    img {
      position: absolute;
    }

    @include before() {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: url(../images/wheel-arrow.svg);
      height: 14px;
      width: 28px;
      z-index: 15;

      @media (max-width: 767px) {
        background-size: contain;
        height: 9px;
        width: 18px;
      }
    }
  }
}

.wheel-animate,
.wheel-bg,
.wheel-shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wheel-shadow {
  z-index: 10;
}

.wheel-animate {
  animation: rotating 5s linear infinite;
  transform: rotate(1turn);
  transform-origin: 50% 50%;
  transition: all 0.5s;
}

@keyframes rotating {
  0% {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    -ms-transform: translate(-50%, -50%) rotate(1turn);
    -moz-transform: translate(-50%, -50%) rotate(1turn);
    -webkit-transform: translate(-50%, -50%) rotate(1turn);
    -o-transform: translate(-50%, -50%) rotate(1turn);
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
