
.profile-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;
  padding-right: 15px;

  &-item {
    display: flex;
    flex-direction: column;
  }

  &-label {
    order: 2;
    font-size: 16px;
    line-height: 24px;
    color: #5f4169;
  }

  &-num {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.03em;
    line-height: 24px;
    color: #a97aff;
    margin-bottom: 4px;
    white-space: nowrap;
    margin-right: 10px;

    svg {
      margin-right: 8px;
      margin-left: -5px;
    }
  }
}