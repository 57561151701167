.in-case-item {
  .winner-num {
    position: absolute;
    top: -12px;
    left: 0;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    text-shadow: -1px -1px 0 #FFC600, 1px -1px 0 #FFC600, -1px 1px 0 #FFC600, 1px 1px 0 #FFC600, 0px 0px 24px #FFC600;
    z-index: 3;

    span {
      position: relative;
    }

    &:before {
      @extend %before-after;
      width: 99px;
      height: 28px;
      background: url("../images/winner-num-1.svg") no-repeat 0 0;
      top: -1px;
      left: -33px;
    }
  }

  &[data-rar="4b69ff"] {
    .img {
      .winner-num {
        text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff, 1px 1px 0 #4b69ff, 0px 0px 24px #4b69ff;

        &:before {
          background-image: url("../images/winner-num-6.svg");
        }
      }
    }
  }

  &[data-rar="e4ae39"] {

  }

  &[data-rar="5e98d9"] {
    .img {
      .winner-num {
        text-shadow: -1px -1px 0 #5e98d9, 1px -1px 0 #5e98d9, -1px 1px 0 #5e98d9, 1px 1px 0 #5e98d9, 0px 0px 24px #5e98d9;

        &:before {
          background-image: url("../images/winner-num-5.svg");
        }
      }
    }
  }

  &[data-rar="b0c3d9"] {

  }

  &[data-rar="d32ce6"] {
    .winner-num {
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 24px #d32ce6;

      &:before {
        background-image: url("../images/winner-num-3.svg");
      }
    }
  }

  &[data-rar="8847ff"] {
    .winner-num {
      text-shadow: -1px -1px 0 #8847ff, 1px -1px 0 #8847ff, -1px 1px 0 #8847ff, 1px 1px 0 #8847ff, 0px 0px 24px #8847ff;

      &:before {
        background-image: url("../images/winner-num-4.svg");
      }
    }
  }

  &[data-rar="eb4b4b"] {
    .img {
      .winner-num {
        text-shadow: -1px -1px 0 #eb4b4b, 1px -1px 0 #eb4b4b, -1px 1px 0 #eb4b4b, 1px 1px 0 #eb4b4b, 0px 0px 24px #eb4b4b;

        &:before {
          background-image: url("../images/winner-num-2.svg");
        }
      }
    }
  }

  &[data-rar="ffd700"] {

  }
}