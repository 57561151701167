@import "../abstracts/extends";

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 999999 !important;
  display: flex;

  &.in.visible {
    opacity: 1;
    transform: translateY(0);
    overflow: hidden;
    overflow-y: auto;
  }
}

.box-modal {
  width: 300px;
  background-color: #242420;
  background-image: linear-gradient(-45deg, #2e2a1e 0%, transparent 50%, transparent 99%, transparent 100%);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.66), 0px 4px 42px 0px rgba(99, 81, 37, 0.24);
  border-radius: 0px 0px 2px;
  padding: 20px 25px 25px 25px;
  position: relative;
  border: 1px solid #3f3f3a;

  .box-modal_close {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: #3d0051 url(../images/close.svg) no-repeat 50% 50%;
    border: 4px solid #9b00ac;
    box-shadow: 0px 0px 28px #d32ce6;
    position: absolute;
    top: -20px;
    right: -22px;
    cursor: pointer;
    transition: all 0.5s;

    @media (max-width: 900px) {
      width: 28px;
      height: 28px;
      top: -5px;
      right: -5px;
      background-size: 10px 10px !important;

      .icon {
        font-size: 20px !important;
      }
    }
    &:hover {
      transform: rotate(180deg);
    }
  }
}

.modal {
  border: 3px solid #d32ce6;
  box-sizing: content-box;
  border-radius: 40px;
  margin: auto !important;

  &-wrap {
    padding: 30px 0;
    margin: auto;
    width: 100%;
  }
}

.default-popup {
  width: 472px;
  border: 7px solid #fff;
  border-radius: 36px;
  padding: 0;
  box-shadow: 0 0 64px #d32ce6, inset 0 0 64px #d32ce6;
  background: rgb(12, 0, 62);
  background: linear-gradient(to bottom, #0c003e 0%, #160140 45%, #640073 75%, #3d0041 100%);

  @media (max-width: 768px) {
    width: 100%;
  }
  &-wrap {
    border: 3px solid #d32ce6;
    border-radius: 30px;
    background: url(../images/popup-honey.svg) no-repeat 0 0;
  }

  .balance-title {
    font-size: 24px;
    letter-spacing: 0.05em;
    line-height: 24px;
    text-align: center;
    color: #f7dbff;
    text-shadow: -1px -1px 0 #c131e4, 1px -1px 0 #c131e4, -1px 1px 0 #c131e4, 1px 1px 0 #c131e4, 0px 0px 3px #ff00b6;
    text-transform: uppercase;
    padding: 23px 0;
  }

  .balance-btns {
    position: relative;
  }

  .btn-skin {
    border: 4px solid #fff;
    border-radius: 24px;
    width: 298px;
    height: 48px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    box-shadow: 0px 0px 32px #d32ce6, inset 0px 0px 32px #d32ce6, 0px 0px 20px #d32ce6, 0px 0px 8px #d32ce6;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #d32ce6;
    text-shadow: 0px 0px 6px rgba(211, 44, 230, 0);
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 27px;
    transition: all 0.5s;

    &:hover {
      color: #fff;
    }
  }

  .btn-modal-sale {
    position: absolute;
    top: -65px;
    right: -248px;
    width: 135px;
    height: 54px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .shadow {
      position: absolute;
      background: -moz-radial-gradient(center, ellipse cover, rgba(255, 198, 0, 0.64) 0%, rgba(59, 0, 65, 0) 64%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 198, 0, 0.64) 0%, rgba(59, 0, 65, 0) 64%);
      background: radial-gradient(ellipse at center, rgba(255, 198, 0, 0.64) 0%, rgba(59, 0, 65, 0) 64%);
      width: 408px;
      height: 210px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      pointer-events: none;
    }

    &:before {
      @extend %before-after;
      background: url(../images/bg-btn-modal-sale.svg) no-repeat 0 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 234px;
      height: 122px;
      transition: all 0.5s;
      pointer-events: none;
    }

    &:after {
      @extend %before-after;
      background: url(../images/bg-btn-modal-sale-hover.svg) no-repeat 0 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 249px;
      height: 122px;
      opacity: 0;
      transition: all 0.5s;
      pointer-events: none;
    }

    &:hover {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .balance-footer {
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #ba63c5;
    margin-bottom: 10px;

    span {
      margin-left: 40px;

      .fa-lock {
        color: #d32ce6;
        margin-right: 5px;
      }
    }
  }
}

.variants-pay {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px 10px -8px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
    cursor: default;
  }

  &-item {
    width: 108px;
    height: 108px;
    margin: 0 8px 16px 8px;
    box-shadow: inset 0px 0px 0px 2px rgba(211, 44, 230, 0.24);
    border-radius: 8px;
    position: relative;
    background: radial-gradient(82.36% 172.22% at 50% 100%, #4c0055 0%, rgba(36, 2, 57, 0.8) 51%, #130333 100%);
    padding-top: 62px;
    cursor: pointer;
    transition: all 0.3s;

    &-badge {
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 22px;
      border-radius: 4px;
      background: #ffc64b;
      border: 2px solid #fffc00;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      font-size: 14px;
      line-height: 24px;
      color: #050b16;
      font-weight: 500;
    }

    &-cost {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      margin-bottom: -2px;
      position: relative;
      z-index: 2;
    }

    &-gift {
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #ffc600;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;

      .icon-gift {
        width: 16px;
        height: 16px;
        background: url(../images/icon-gift.svg) no-repeat;
        margin-right: 2px;
      }

      .fa-ruble {
        margin-left: 5px;
        margin-top: 2px;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 4px rgba(211, 44, 230, 0.24);
    }

    &.active {
      &:before {
        opacity: 1;
      }
    }
  }

  & > :before {
    @extend %before-after;
    width: 196px;
    height: 196px;
    background: url(../images/bg-modal-pay-hover.svg) no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all 0.5s;
    opacity: 0;
  }

  & > :after {
    @extend %before-after;
    width: 104px;
    height: 80px;
    top: -14px;
  }

  & > :nth-child(1):after {
    background: url(../images/icon-balance-1.svg) no-repeat;
  }

  & > :nth-child(2):after {
    background: url(../images/icon-balance-2.svg) no-repeat;
  }

  & > :nth-child(3):after {
    background: url(../images/icon-balance-3.svg) no-repeat;
  }

  & > :nth-child(4):after {
    background: url(../images/icon-balance-4.svg) no-repeat;
  }

  & > :nth-child(5):after {
    background: url(../images/icon-balance-5.svg) no-repeat;
  }

  & > :nth-child(6):after {
    background: url(../images/icon-balance-6.svg) no-repeat;
  }

  & > :nth-child(7):after {
    background: url(../images/icon-balance-7.svg) no-repeat;
  }

  & > :nth-child(8):after,
  & > :nth-child(9):after {
    background: url(../images/icon-balance-8.svg) no-repeat;
  }
}

.balance-promo-code {
  text-align: center;

  .have-promo {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #d32ce6;
    text-shadow: 0px 0px 32px #d32ce6;
    border-bottom: 1px solid #d32ce6;
    text-decoration: none;
  }

  .modal-money-info {
    display: flex;
    justify-content: center;
    width: 344px;
    margin: 34px auto 0 auto;

    &-item {
      position: relative;
      padding: 0 36px;
      white-space: nowrap;

      &-title {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #8a4592;
        text-shadow: 0px 0px 32px #d32ce6;
      }

      &-cost {
        font-weight: 900;
        font-size: 48px;
        letter-spacing: 0.03em;
        line-height: 63px;
        text-align: center;
        color: #ffc600;
        text-shadow: 0px 0px 48px #ffc600;

        .fa-ruble {
          font-weight: bold;
          font-size: 42px;
        }
      }
    }

    & > :first-child {
      &:before {
        @extend %before-after;
        width: 2px;
        height: 80px;
        background: linear-gradient(
          rgba(32, 8, 66, 0) 0%,
          #860b95 49.88%,
          rgba(135, 11, 149, 1) 50.12%,
          rgba(32, 8, 66, 0) 100%
        );
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    & > :last-child {
      .modal-money-info-item-cost {
        color: #fff;
        // text-shadow: 0px 0px 48px #ffc600, 0px 0px 4px #ffc600,
        //   -2px -2px 0 rgba(255, 198, 0, 0.64),
        //   2px -2px 0 rgba(255, 198, 0, 0.64), -2px 2px 0 rgba(255, 198, 0, 0.64),
        //   2px 2px 0 rgba(255, 198, 0, 0.64), 0px 0px 2px rgba(255, 198, 0, 0.64);
        -webkit-text-stroke: 1px #ffc600;
        text-shadow: 0px 0px 14px #ffc600;
      }

      &:before {
        @extend %before-after;
        width: 2px;
        height: 80px;
        background: linear-gradient(rgba(32, 8, 66, 0) 0%, rgba(41, 0, 45, 1) 50.12%, rgba(32, 8, 66, 0) 100%);
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .promo-done {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
    position: relative;
    padding-top: 28px;

    .balance-promo-input {
      width: 420px;
      height: 36px;
      background: linear-gradient(to right, #2d0070 0%, #69057d 100%);
      padding: 2px;
      border-radius: 18px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:hover {
        background: linear-gradient(to right, #490aa8 0%, #930aae 100%);
      }

      &:focus {
        outline: none;
      }
    }

    .balance-notify {
      padding-top: 8px;
      opacity: 0;
      transition: all 0.5s;

      .done {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ffc600;
      }

      .error {
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #ba63c5;
      }
    }

    .balance-promo-reset {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: #3d0051;
      border: 2px solid #9b00ac;
      box-shadow: 0px 0px 28px #d32ce6;
      top: -4px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;

      &:hover {
        transform: rotate(180deg);
      }
    }

    input {
      background: linear-gradient(to right, #000746 0%, #370047 100%);
      border: 0;
      width: 100%;
      height: 100%;
      border-radius: 18px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #d32ce6;
      font-weight: bold;

      @include input-placeholder {
        color: #d32ce6;
        opacity: 0.4;
        text-shadow: 0px 0px 32px #d32ce6;
        font-size: 16px;
      }

      &:focus {
        outline: none;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }
  }

  &.active {
    .have-promo {
      display: none;
    }

    .promo-done {
      max-height: 150px;
    }
  }

  &.error,
  &.done {
    .balance-notify {
      opacity: 1;
    }
  }
}

.popup-lacky {
  max-width: 384px;
  background: rgb(13, 3, 41);
  background: -moz-linear-gradient(-45deg, rgba(13, 3, 41, 1) 0%, rgba(37, 0, 85, 1) 50%, rgba(119, 0, 123, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(13, 3, 41, 1) 0%, rgba(37, 0, 85, 1) 50%, rgba(119, 0, 123, 1) 100%);
  background: linear-gradient(135deg, rgba(13, 3, 41, 1) 0%, rgba(37, 0, 85, 1) 50%, rgba(119, 0, 123, 1) 100%);
  box-shadow: 0 0 64px #d32ce6;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
  .img {
    margin-top: -35px;
    position: relative;
    width: calc(100% + 50px);
    transform: translateX(-25px);

    &:before {
      @extend %before-after;
      background: url(../images/money-shadow.svg) no-repeat 50% 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 312px;
      height: 312px;
    }

    img {
      position: relative;
    }
  }

  .promo-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0 22px 0;

    @media (max-width: 768px) {
      justify-content: space-between;

      .text {
        padding-right: 12px;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column;

      .text {
        margin-left: 12px;
      }
      .num {
        margin: 0 !important;
        padding-top: 12px;
      }
    }
    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #f7c0fe;
      text-shadow: 0px 0px 32px #d32ce6;
      text-align: right;
      margin: 0 12px;
    }

    .num {
      font-weight: 900;
      font-size: 48px;
      letter-spacing: 0.03em;
      line-height: 24px;
      color: #fff;
      text-shadow: -2px -2px 0 rgba(255, 198, 0, 0.64), 2px -2px 0 rgba(255, 198, 0, 0.64),
        -2px 2px 0 rgba(255, 198, 0, 0.64), 2px 2px 0 rgba(255, 198, 0, 0.64), 0px 0px 4px #ffc600, 0px 0px 48px #ffc600;
      margin: 0 12px;
    }
  }

  .promo-code {
    height: 48px;
    margin: 0 29px;
    background: rgb(45, 0, 112);
    background: -moz-linear-gradient(-45deg, rgba(45, 0, 112, 1) 0%, rgba(105, 5, 125, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(45, 0, 112, 1) 0%, rgba(105, 5, 125, 1) 100%);
    background: linear-gradient(135deg, rgba(45, 0, 112, 1) 0%, rgba(105, 5, 125, 1) 100%);
    border-radius: 72px;
    overflow: hidden;
    padding: 2px;
    margin-bottom: 6px;

    input {
      width: 100%;
      height: 100%;
      background: rgb(0, 7, 70);
      background: -moz-linear-gradient(-45deg, rgba(0, 7, 70, 1) 0%, rgba(55, 0, 71, 1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(0, 7, 70, 1) 0%, rgba(55, 0, 71, 1) 100%);
      background: linear-gradient(135deg, rgba(0, 7, 70, 1) 0%, rgba(55, 0, 71, 1) 100%);
      border: 0;
      text-align: center;
      font-weight: 900;
      font-size: 20px;
      letter-spacing: 0.2em;
      line-height: 41px;
      color: #d32ce6;
      border-radius: 72px;

      &:focus {
        outline: none;
        border: 0;
      }
    }
  }

  .promo-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: #8a4592;
    text-shadow: 0px 0px 32px #d32ce6;

    .timer {
      font-weight: bold;
      margin-left: 12px;
    }
  }
  .balance-btns {
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      margin-top: -30px;
    }
  }
}

#prize {
  background-image: url(../images/prize-bcgr.png);
  background-repeat: no-repeat;
  background-position: 20px 0;
  background-color: #050b16;
  box-shadow: 0 12px 30px #000;
  overflow: visible;
  border: 3px solid #e4ae39;
  border-radius: 15px;
  margin: -3px;
}

#prize .box-modal {
  width: 100%;
  background: none;
  border: 0;
  text-align: center;
}

#prize .box-modal-wrap:before {
  content: "";
  position: absolute;
  top: -110px;
  left: -160px;
  background: url(../images/popup-claks.png) no-repeat;
  width: 580px;
  height: 827px;
  display: block;
  z-index: -1;
}

#prize .prize-title {
  font-size: 18px;
  width: 100%;
  margin: 0 auto 140px;
  position: relative;
  letter-spacing: 1px;
  color: #ffc600;
  font-weight: 700;
  text-transform: uppercase;
}

#prize .prize-title:after {
  content: "";
  position: absolute;
  display: block;
  width: 76px;
  height: 86px;
  background: url(../images/prize-icon.png);
  left: 50%;
  margin-left: -38px;
  top: 75px;
}

.we-give .we-give-quantity {
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 1.5px;
  margin-bottom: 15px;
  color: #ffc600;
  font-weight: 700;
  text-transform: uppercase;
}

.we-give .we-give-descr {
  font-size: 14px;
  font-weight: 400;
  color: #ccc;
  margin-bottom: 50px;
  line-height: 16px;
}

#prize button,
.enter-fb,
.enter-steam,
.enter-vk {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

#prize button {
  width: 250px;
  margin: 0 auto 30px;
  text-transform: none;
  text-align: left;
  border-radius: 20px;
  display: block;
  position: relative;
  line-height: 27px;
}

.enter-fb,
.enter-steam,
.enter-vk,
.open-cases-btns,
.open-cases-btns a {
  text-align: center;
}

.enter-fb,
.enter-fb .icon-fb,
.enter-steam,
.enter-vk,
.enter-vk .icon-vk {
  display: inline-block;
}

.enter-fb,
.enter-steam,
.enter-vk,
.open-cases h1 {
  line-height: 44px;
}

.enter-steam {
  width: 250px;
  height: 50px;
  background-color: rgba(255, 198, 0, 0.12);
  border: 3px solid #ffc600;
  border-radius: 25px;
  box-shadow: 0 7px 25.8px 2.2px rgba(255, 198, 0, 0.55);
  color: #ffc600;
  margin-bottom: 31px;
}

.enter-steam .icon-steam {
  background: url(../images/popup-icon-steam.svg) no-repeat;
  background-size: contain;
  width: 45px;
  height: 45px;
  display: inline-block;
  margin: 0 11px 0 5px;
  float: right;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
    background: url(../images/popup-icon-steam-hover.svg) no-repeat;
    background-size: contain;
    opacity: 0;
  }
}

.enter-steam:hover .icon-steam:before {
  opacity: 1;
}

.enter-steam:hover {
  color: #fff;
  background: #ffc600;
}

.enter-vk {
  background-color: rgba(94, 152, 217, 0.12);
  border: 3px solid #5e98d9;
  border-radius: 25px;
  box-shadow: 0 7px 25.8px 2.2px rgba(94, 152, 217, 0.55);
  color: #5e98d9;
  width: 250px;
  height: 50px;
  margin-bottom: 31px;
}

.enter-vk .icon-vk {
  float: right;
  background: url(../images/popup-icon-vk.svg) no-repeat;
  background-size: contain;
  width: 35px;
  height: 20px;
  margin: 12px 15px 0 35px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 20px;
    background: url(../images/popup-icon-vk-hover.svg) no-repeat;
    background-size: contain;
    opacity: 0;
  }
}

.enter-vk:hover {
  color: #fff;
  background: #5e98d9;
}

.enter-vk:hover .icon-vk:before {
  opacity: 1;
}

.enter-fb {
  color: #545cff;
  background-color: rgba(75, 105, 255, 0.12);
  border: 3px solid #545cff;
  border-radius: 25px;
  box-shadow: 0 7px 25.8px 2.2px rgba(84, 92, 255, 0.55);
  width: 250px;
  height: 50px;
  margin-bottom: 31px;
}

.enter-fb:hover {
  color: #fff;
  background: #002bff;
  border-color: #002bff;
}

.enter-fb .icon-fb {
  background: url(../images/popup-icon-fb.svg) no-repeat;
  background-size: contain;
  width: 18px;
  height: 34px;
  margin: 5px 20px 0 -2px;
  float: right;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 34px;
    background: url(../images/popup-icon-fb-hover.svg) no-repeat;
    background-size: contain;
    opacity: 0;
  }
}

.enter-fb:hover .icon-fb:before {
  opacity: 1;
}

.refPoup .box-modal:not(.default-popup) {
  width: 382px;
  background-color: #1a1b2a;
  border: 2px solid #d32ce6;
  border-radius: 40px;
  padding: 16px 0;
  border: 0;
}

.adv-title {
  padding-left: 30px;
  color: #ffc600;
  font-size: 18px;
  text-transform: uppercase;
}

.adv-item {
  color: #fef2c8;
  font-size: 16px;
  font-weight: 400;
  line-height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.adv-item span {
  padding-left: 25px;
  line-height: 24px;
  display: table-cell;
  text-align: left;
  width: 100%;
}

.adv-item img {
  margin-left: 25px;
}

.adv-item:hover {
  background-image: linear-gradient(to right, rgba(141, 29, 208, 0.2) 0, transparent 100%);
}

.adv-item:hover span {
  color: #ffc600;
}

.sell-text {
  width: 322px;
  height: 165px;
  background-color: rgba(204, 204, 204, 0.05);
  margin: 0 auto 35px;
  border-radius: 10px;
  position: relative;
}

.sell-text .text-textarea {
  width: 100%;
  height: 100%;
  background: 0 0;
  border: 0;
  color: #f7b4ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding: 15px 15px 70px;
  overflow: hidden;
  font-family: Roboto, sans-serif;
  resize: none;
}

.adv-soc,
.btn-notify {
  text-align: center;
}

.sell-text .btn-pink {
  width: 152px;
  height: 40px;
  background-color: #301d41;
  border: 2px solid #d32ce6;
  border-radius: 20px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 34px;
  text-decoration: none;
  color: #d32ce6;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 7px 16.6px 1.4px rgba(211, 44, 230, 0.3);
}

.sell-text .btn-pink:before {
  content: "";
  background: url(../images/icon-copy-pink.svg) no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  position: relative;
  top: 2px;
  display: inline-block;
}

.sell-text .btn-pink:hover {
  background-color: #d32ce6;
  color: #fff;
}

.sell-text .btn-pink:active:before,
.sell-text .btn-pink:hover:before {
  content: "";
  background: url(../images/icon-copy-white.svg) no-repeat;
}

.sell-text .btn-pink:active {
  background-color: #9920a7;
  border-color: #9920a7;
  box-shadow: none;
  color: #fff;
}

.refPoup.box-modal-wrap:before {
  content: "";
  top: -150px;
  left: -150px;
}

.adv-soc a {
  font: normal normal normal 34px/1 FontAwesome;
  color: #888;
  text-decoration: none;
}

.adv-soc a {
  display: inline-block;
  transition: all 0s;
}

.adv-soc .vk {
  width: 54px;
  height: 37px;
}

.adv-soc .vk:before {
  content: "\f189";
}

.adv-soc .vk:hover:before {
  content: "";
  color: #5f98d9;
  text-shadow: 0 0 10px rgba(95, 152, 217, 0.5);
}

.adv-soc .fb {
  width: 33px;
  height: 49px;
  margin: 0 65px;
  padding-top: 6px;
}

.adv-soc .fb:hover {
  color: #0d38fb;
  text-shadow: 0 0 10px rgba(13, 56, 251, 0.5);
}

.adv-soc .tw {
  background: url(../images/popup-soc-tw.svg) 8px 4px no-repeat;
  width: 50px;
  height: 43px;
}

.adv-soc .tw:hover {
  background: url(../images/popup-soc-tw-hover.svg) no-repeat;
}

.adv-item img,
.adv-item span,
.adv-soc a {
  vertical-align: middle;
}

.adv-items,
.adv-title {
  margin-bottom: 30px;
  text-align: left;
}

.adv-soc .fb:before {
  content: "\f09a";
}

.adv-soc .instagram:before {
  content: "\f16d";
}

.adv-soc .vk:hover:before {
  content: "\f189";
  color: #5f98d9;
  text-shadow: 0 0 10px rgba(95, 152, 217, 0.5);
}

.adv-soc .fb:hover {
  color: #0d38fb;
  text-shadow: 0 0 10px rgba(13, 56, 251, 0.5);
}

.adv-soc .instagram {
  margin-left: 7px;
}

.adv-soc .instagram:hover {
  width: 30px;
  height: 34px;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#bonus-popup .box-modal {
  width: 382px;
  background-color: #1a1b2a;
  border: 2px solid #d32ce6;
  border-radius: 35px;
  padding: 18px 29px;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.004), 0 16px 48px 0 rgba(211, 44, 230, 0.3);
  text-align: center;
}

#bonus-popup input[type="text"] {
  padding: 0 20px;
}

#bonus-popup input[type="text"]:focus,
#bonus-popup textarea:focus {
  border-color: #ccc;
}

#bonus-popup .box-modal .ui.button.btn-yellow {
  color: #e4ae39;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border: 2px solid #e4ae39;
  border-radius: 20px;
  box-shadow: 0 7px 16.6px 1.4px rgba(228, 174, 57, 0.3);
  width: auto;
  margin: 15px auto;
  background: 0 0;
  line-height: 36px;
  position: relative;
  padding: 0 18px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    &:before {
      background-position: 0 -17px;
    }
  }
}

#bonus-popup .box-modal .ui.button.btn-yellow:hover {
  background-color: #e4ae39;
  color: #fff;
}

#bonus-popup .box-modal .ui.button.btn-yellow:active {
  background-color: #d79305;
  color: #fff;
  box-shadow: none;
  border-color: #d79305;
}

#bonus-popup .bonus-link {
  text-decoration: none;
}

.bonus-modal-code .btn-green:not(.loading):before {
  content: "";
  display: inline-block;
  background: url(../images/icon-prize-white.png) no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

#bonus-popup .refer-input input {
  width: 100%;
  height: 46px;
  background-color: rgba(211, 44, 230, 0.2);
  background-image: linear-gradient(to top, transparent 0, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.09) 100%);
  border: 2px solid #d32ce6;
  border-radius: 22px;
}

#bonus-popup .refer-input input:focus {
  outline: none;
}

.bonus-modal-code .btn-green:hover {
  background-color: #56d30d;
  color: unset;
}

.bonus-modal-code .btn-green:active {
  background: #3b5906;
  border-color: #3b5906;
}

.bonus-modal-code .btn-green:focus {
  outline: none;
}

.block-hidden-text {
  overflow: hidden;
  display: block;
  width: 100%;
}

#bonus-popup .box-modal .ui.button.btn-yellow:before {
  content: "";
  display: inline-block;
  background: url(../images/icons-earn.svg) no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 11px;
  position: relative;
  top: 2px;
}

#bonus-popup:before,
#popup-error:before {
  background: url(../images/popup-claks-2.png) no-repeat;
  width: 338px;
  height: 380px;
  left: -80px;
  top: -25px;
}

.bonus-title {
  color: #d32ce6;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.bonus-modal-code .bonus-link,
.bonus-modal-code .label,
.hidden-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.bonus-modal-code .label {
  float: left;
  color: #ffc600;
}

.bonus-modal-code .label:before {
  background: url(../images/icon-prize.svg) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.bonus-modal-code .bonus-link {
  color: #d32ce6;
  border-bottom: 1px solid rgba(211, 44, 230, 0.34);
  float: right;
}

.bonus-modal-code .bonus-link:hover {
  color: #f17aff;
  border-bottom: 1px solid rgba(241, 122, 255, 0.34);
}

.hidden-text {
  color: #ccc;
  padding-top: 20px;
  text-align: left;
}

.hidden-text a {
  color: #00acec;
}

.hidden-text a:hover {
  color: #fff;
}

.bonus-modal-code .refer-input {
  padding-top: 20px;
  clear: both;
  margin-bottom: 40px;
}

.bonus-modal-code .refer-input input {
  color: #ffc600;
  font-size: 16px;
}

.bonus-modal-code .refer-input input::-webkit-input-placeholder {
  color: #ffc600;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.bonus-modal-code .refer-input input::-moz-placeholder {
  color: #ffc600;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.bonus-modal-code .refer-input input:-moz-placeholder {
  color: #ffc600;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.bonus-modal-code .refer-input input:-ms-input-placeholder {
  color: #ffc600;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.bonus-modal-code .btn-green {
  height: 60px;
  background-color: #00a700;
  border: 3px solid #56d30d;
  border-radius: 30px;
  box-shadow: 0 7px 18.4px 1.6px rgba(86, 211, 13, 0.4);
  display: inline-block;
  line-height: 56px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  padding: unset;
}

.bonus-modal-code .label:before {
  content: "";
  background: url(../images/icon-prize.svg) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

#popup-error .box-modal {
  width: 387px;
  background-color: #1a1b2a;
  border-radius: 37px;
  padding: 19px 30px;
  border: 2px solid #eb4b4b;
  margin: -3px -2px;
}

#popup-error .error-title {
  color: #eb4b4b;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 25px;
  text-transform: uppercase;
}

#popup-error .error-text {
  text-align: left;
  color: #ccc;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 30px;
}

#popup-error .btn-green {
  width: 264px;
  text-align: center;
}

#popup-error .btn-green {
  height: 60px;
  background-color: #00a700;
  border: 3px solid #56d30d;
  border-radius: 30px;
  box-shadow: 0 7px 18.4px 1.6px rgba(86, 211, 13, 0.4);
  line-height: 56px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

#popup-error .btn-green .icon-plus {
  background: url(../images/icon-plus-btn.svg) no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: baseline;
  position: relative;
  display: inline-block;
  margin-right: 12px;
}

#popup-error .btn-green:hover {
  background-color: #56d30d;
  color: unset;
}

#popup-error .btn-green:active {
  background: #3b5906;
}

#withdraw-all-items {
  border: 3px solid #4b69ff;
  border-radius: 40px;
  margin: -3px;
  position: relative;

  .box-modal_close {
    border-color: #4b69ff;
    background: #150051 url(../images/close-blue.svg) no-repeat 50% 50%;
    box-shadow: 0px 0px 28px #4b69ff;
    z-index: 2;
  }

  &:before {
    content: "";
    display: block;
    background: url(../images/modal-widthdraw-bg.png) no-repeat 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1176px;
    height: 742px;
    z-index: -1;
  }

  .box-modal {
    background: none;
    box-shadow: none;
    border: 0;
    width: 100%;
    padding: 0;
    box-shadow: 0 0 64px #4b69ff;
    border: 7px solid #fff;
    border-radius: 36px;
  }

  .withdraw-modal {
    display: flex;
    border: 3px solid #4b69ff;
    border-radius: 30px;
    box-shadow: inset 0px 0px 16px #4b69ff, inset 0px 0px 8px #4b69ff;
    background: linear-gradient(#0c003e 0%, #250140 22.2%, #1a037a 65.56%, #0c001a 100%);
    min-height: 300px;
    position: relative;

    @media (max-width: 992px) {
      flex-direction: column;
    }
    &-item {
      padding: 25px 28px;
      display: flex;
      align-items: center;
      background: url(../images/modal-withdraw-bg-item.svg) no-repeat 0 0;

      @media (max-width: 992px) {
        background: none;
      }
      .in-case-item {
        width: 180px;
        position: relative;
        margin: 0 auto;

        .in-case-info {
          color: #d6ddff;
        }

        .img {
          height: 142px;
        }
      }
    }

    &-content {
      padding: 25px 28px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 24px;
        letter-spacing: 0.05em;
        line-height: 24px;
        text-align: center;
        color: #d6ddff;
        text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff, 1px 1px 0 #4b69ff, 0px 0px 3px #4b69ff;
        text-transform: uppercase;
        margin-bottom: 36px;
      }

      .btn {
        min-width: auto;
        white-space: nowrap;
        border-width: 2px;
        position: relative;
        z-index: 4;
        display: inline-flex;

        .text {
          font-size: 13px;
        }

        &.btn-sell {
          svg {
            min-width: 32px;
            min-height: 32px;
            max-height: 32px;
            margin-right: 0;
          }

          &:before {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 2px solid #00f308;
            opacity: 0;
            border-radius: 25px;
          }

          .text {
            padding: 0;
            margin: 0 12px;
          }

          .cost {
            height: calc(100% - 4px);
            margin-left: auto;
            font-size: 13px;
            margin-right: 2px;
            width: auto;
          }

          .fa {
            top: 0px;
            font-size: 13px;
          }

          &:hover:before {
            opacity: 1;
          }
        }

        &.btn-steam,
        &.btn-withdraw {
          min-width: 212px;
          max-width: 212px;
          display: flex;
          align-items: center;
          padding-right: 20px;
          background: linear-gradient(#0370ff 0%, #0652ff 100%);
          border: 2px solid #6595ff;
          transition: all 0.3s;
          position: relative;

          &:before {
            content: "";
            display: block;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 2px solid #6595ff;
            opacity: 0;
            border-radius: 25px;
            transition: all 0.3s;
          }

          svg {
            min-width: 32px;
            min-height: 32px;
            max-height: 32px;
            margin-right: 4px;
            transform: rotate(180deg);
          }

          .text {
            font-size: 14px;
            color: #fff;
            text-shadow: 0px 0px 6px #f7c0fe;
            display: flex;
            align-items: center;
            padding: 0;
            background: none;
            font-weight: 900;
            transition: all 0.3s;
            padding-top: 1px;
          }

          &:hover:before {
            opacity: 1;
          }
        }

        &.btn-steam {
          background: #e29700;
          border-color: #fec500;

          .fa {
            width: 42px;
            text-align: center;
          }

          &:before {
            border-color: #fec500;
          }
        }
      }
    }

    &-btns-item {
      margin-bottom: 20px;
      margin-right: auto;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      @media (max-width: 640px) {
        flex-direction: column;

        .btn.btn-withdraw {
          width: 100%;
          max-width: 100%;
          margin-bottom: 4px;
        }
      }
      .method-info {
        position: relative;
        top: 0;
        left: 0;
        font-size: 14px;
        line-height: 20px;
        color: #d6ddff;
        border: 1px solid rgba(211, 44, 230, 0);
        text-shadow: 0px 0px 32px #4b69ff;
        width: auto;
        margin-top: 0;
        z-index: 3;
        padding-left: 30px;
        opacity: 0;
        transition: all 0.3s;

        @media (max-width: 640px) {
          opacity: 1;
          padding-left: 0;
        }
        &:before {
          content: "";
          position: absolute;
          background: url(../images/withdraw-modal-info.svg) no-repeat 0 0;
          width: 9px;
          height: 80px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          z-index: 2;

          @media (max-width: 640px) {
            display: none;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -133px;
          background: url(../images/withdraw-modal-info-bg.svg) no-repeat 0 0;
          display: block;
          transform: translateY(-50%);
          width: 142px;
          height: 80px;
          z-index: 2;

          @media (max-width: 640px) {
            display: none;
          }
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100px;
          height: 100px;
          background: url(../images/withdraw-icon-info.svg) no-repeat 0 0;
          display: block;
          transform: translate(-50%, -50%);
          margin: -25px 0 0 -108px;
          margin: -25px 0 0 -46%;
          z-index: 2;

          @media (max-width: 640px) {
            display: none;
          }
        }

        .text {
          .red {
            color: #eb4b4b;
            display: flex;
          }
        }
      }

      &.modal-btns-sell {
        padding-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;

        .method-info {
          opacity: 1;
        }

        &:before {
          content: "";
          width: 240px;
          height: 2px;
          background: url(../images/withdraw-modal-line.svg) no-repeat 0 0;
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          margin-left: -80px;

          @media (max-width: 640px) {
            display: none;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(../images/modal-withdraw-bg-2.svg) no-repeat 0 0;
          width: 424px;
          height: 110px;
          pointer-events: none;
          z-index: 2;
          margin-top: -10px;
          margin-left: -80px;

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &:hover {
        .method-info {
          opacity: 1;
        }
      }

      &.hide {
        .method-info {
          opacity: 0 !important;
        }
      }
    }
  }
}

#won {
  border: 3px solid #4b69ff;
  border-radius: 40px;
  margin: -3px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1254px;
    height: 1263px;
    background: url(../images/won-bg-4.svg) no-repeat 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box-modal_close {
    border-color: #4b69ff;
    background: #150051 url(../images/close-blue.svg) no-repeat 50% 50%;
    box-shadow: 0px 0px 28px #4b69ff;
    z-index: 2;
  }

  .box-modal {
    width: 560px;
    box-shadow: 0 0 64px #4b69ff;
    border: 7px solid #fff;
    border-radius: 36px;
    padding: 0;
    background: url(../images/won-bg-3.svg) no-repeat 0 0;
    background-size: cover;
  }

  .box-modal-won {
    position: relative;
    border: 3px solid #4b69ff;
    box-shadow: inset 0px 0px 16px #4b69ff, inset 0px 0px 8px #4b69ff;
    border-radius: 30px;
  }

  .won-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border-radius: 28px 28px 0 0;
    font-size: 24px;
    letter-spacing: 0.05em;
    color: #d6ddff;
    text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff, 1px 1px 0 #4b69ff, 0px 0px 3px #4b69ff,
      0px 0px 36px #9aaaff;
    text-transform: uppercase;
    border-bottom: 0;
  }

  .items {
    max-height: 470px;
    overflow: auto;
    padding: 20px;
    background: linear-gradient(#0a0040 0%, rgba(16, 2, 57, 0.8) 55.22%, #0b0333 100%);
    border: 2px solid #09196d;
    border-left: 0;
    border-right: 0;
    position: relative;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: rgba(106, 156, 255, 0.3);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../images/won-bg-2.svg) no-repeat 0 0;
      background-size: cover;
      display: block;
    }

    .in-case-item {
      width: 100%;
      margin: 0 0 20px 0;
      height: 116px;
      position: relative;

      .in-case-item-bg {
        -webkit-mask-size: 100% auto;
      }
      .in-case-info {
        position: absolute;
        top: 0;
        left: 10px;
        padding: 22px 0 0 8px;
        font-size: 14px;
        line-height: 24px;
        color: #d6ddff;
        text-shadow: 0px 0px 8px #4b69ff;
        z-index: 2;
        height: 100%;

        .in-case-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 50%;

          &:before {
            top: 5px;
          }
        }

        .in-case-info-btns {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0 -4px;
          padding-bottom: 10px;

          a,
          button {
            margin: 0 4px;
          }

          .btn-sell {
            white-space: nowrap;
            border-width: 2px;
            position: relative;
            z-index: 4;

            &:before {
              width: calc(100% - 4px);
              height: calc(100% - 4px);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: 2px solid #00f308;
              opacity: 0;
              border-radius: 25px;
            }

            .btn-sale-cost:not(.percent) {
              border-radius: 2px 14px 14px 2px;
            }
            svg {
              min-width: 32px;
              min-height: 32px;
              max-height: 32px;
              margin-right: 0;
            }

            .text {
              padding: 0;
              margin: 0 12px;
              font-size: 13px;
              white-space: nowrap;
            }

            .cost {
              min-width: 61px;
              height: calc(100% - 4px);
              margin-left: auto;
              font-size: 13px;
              margin-right: 2px;
            }

            &:hover {
              &:before {
                opacity: 1;
              }
            }

            &.btn-disabled {
              border-radius: 18px;
              background: rgba(33, 0, 43, 0.8);
              border: 2px solid #736687;
              min-width: auto;
              pointer-events: none;

              svg {
                margin-left: 0;

                path {
                  fill: #736687;
                }
              }

              .cost {
                background: none;
                border-radius: 0;
                padding: 0;
                margin: 0 10px 0 5px;
                min-width: auto;
                height: auto;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                color: #736687;
                border: 1px solid rgba(211, 44, 230, 0);
              }

              .text {
                margin-right: 0;
                margin-left: 0;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                color: #736687;
                border: 1px solid rgba(211, 44, 230, 0);
                text-shadow: none;
              }

              &:before {
                display: none;
              }
            }
          }

          .btn-home,
          .btn-contracts {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            position: relative;
            background: linear-gradient(#7d36ff 0%, #3900a1 100%);
            border: 2px solid #8847ff;

            svg {
              min-width: 24px;
              width: 32px;
              height: 32px;
            }

            span {
              position: absolute;
              top: calc(100% - 2px);
              left: 50%;
              transform: translate(-50%, -10px);
              height: 20px;
              border-radius: 4px;
              background: #7d36ff;
              background: linear-gradient(to right, #7d36ff 0%, #3900a1 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #fff;
              white-space: nowrap;
              padding: 0 8px;
              opacity: 0;
              transition: all 0.5s;
              z-index: -1;
            }

            &:hover {
              border: 4px solid #8847ff;

              span {
                opacity: 1;
                transform: translate(-50%, 5px);
              }
            }
          }

          .btn-home {
            background: linear-gradient(#1c1132 0%, #120929 100%);
            border: 2px solid #323c98;

            span {
              background: linear-gradient(to right, #323c99 0%, #151d6b 100%);
            }

            svg {
              min-width: 30px;
              width: 30px;
              height: 30px;
            }

            &:hover {
              border: 4px solid #323c98;

              span {
                opacity: 1;
                transform: translate(-50%, 5px);
              }
            }
          }

          .btn-withdraw {
            background: linear-gradient(#0370ff 0%, #0652ff 100%);
            border: 2px solid #6595ff;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 36px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            position: relative;

            svg {
              min-width: 24px;
              width: 32px;
              height: 32px;
            }

            span {
              position: absolute;
              top: calc(100% - 2px);
              left: 50%;
              transform: translate(-50%, -10px);
              height: 20px;
              border-radius: 4px;
              background: #0370ff;
              background: linear-gradient(to right, #0370ff 0%, #0652ff 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #fff;
              white-space: nowrap;
              padding: 0 8px;
              opacity: 0;
              transition: all 0.5s;
              z-index: -1;
            }

            &:hover {
              border: 4px solid #6595ff;

              span {
                opacity: 1;
                transform: translate(-50%, 5px);
              }
            }
          }
        }
      }

      .img {
        margin-bottom: 0;
        display: flex;

        img {
          width: 144px;
          height: 144px;
          margin-left: auto;
        }
      }

      .in-case-cost {
        top: -10px;
        height: 24px;
        font-size: 16px;
      }
    }
  }

  .won-footer {
    .case-play-win-btns {
      padding: 24px 42px 4px 42px;
      margin-top: 0;
      flex-wrap: wrap;

      .btn-back,
      .btn {
        margin-bottom: 20px;
      }

      .btn-sell {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 300px;
        min-width: 202px;
        line-height: 35px;
        padding: 0;

        .btn-sale-cost {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          height: 28px;

          &.percent {
            &:before,
            &:after {
              display: none;
            }
          }
        }

        .btn-sale-cost-wrap {
          top: -18px;

          .btn-sale-cost {
            &.percent {
              right: auto;
            }
          }
        }
      }
    }
  }
}

.fade-enter {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.popup-free-case {
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
  .balance-title {
    margin-bottom: 11px;
    padding-bottom: 0;
  }

  .balance-sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #f7c0fe;
    border: 1px solid rgba(211, 44, 230, 0);
    text-shadow: 0px 0px 32px #d32ce6;
    max-width: 260px;
    margin: 0 auto;
  }

  .img {
    margin-top: -5px;

    img {
      width: 100%;
      max-width: 300px;
      display: block;
      margin: 0 auto;
    }
  }

  .popup-free-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffdf77;
    border: 1px solid rgba(211, 44, 230, 0);
    text-shadow: 0px 0px 32px #ffc600;
    max-width: 220px;
    margin: 0 auto;
  }

  .authorization {
    padding: 0 0 10px 15px;
    height: auto;
    overflow: visible;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .open-cases-btns {
    margin-bottom: 0;
  }
}

.withdraw-none-items {
  background: linear-gradient(#0c003e 0%, #160140 30.13%, #0c3065 64.81%, #0c001a 100%);

  &-content {
    border: 3px solid #4b69ff;
    box-shadow: inset 0px 0px 16px #4b69ff, inset 0px 0px 8px #4b69ff;
    border-radius: 30px;
  }

  &-head {
    padding: 20px 25px;
    display: flex;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }
    &-title {
      max-width: calc(100% - 208px);

      @media (max-width: 640px) {
        max-width: 100%;
      }
    }

    .title {
      margin-bottom: 9px;
      font-size: 24px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-align: left;
      color: #d6ddff;
      text-transform: uppercase;
      text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff, 1px 1px 0 #4b69ff, 0px 0px 3px #4b69ff,
        0px 0px 24px #4b69ff;
    }

    .text {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      color: #736687;
    }

    .title-items {
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.1em;
      line-height: 24px;
      color: #4b69ff;
      text-shadow: 0px 0px 36px #4b69ff;
      margin-top: 56px;
      text-transform: uppercase;

      @media (max-width: 640px) {
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }

    .in-case-item {
      width: 180px;
      position: relative;
      margin-bottom: 0;

      .in-case-item-name,
      .in-case-item-desc {
        font-size: 14px;
        color: #d6ddff;
        text-shadow: 0px 0px 8px #4b69ff;
      }

      &[data-rar] {
        .img {
          background-image: linear-gradient(to bottom, rgba(6, 197, 12, 0.12), rgba(6, 197, 12, 0.5));
          box-shadow: 0 0 28px #06c50c, inset 0 0 28px #06c50c, inset 0 0 4px #06c50c !important;
          border-color: #06c50c;

          &:before {
            background-image: radial-gradient(circle 100px at 50% 129.77%, #06c50c 0, #06c50c 50%, transparent 80%);
            border: 0 !important;
            box-shadow: none !important;
          }

          &:after {
            border-color: #06c50c;
            box-shadow: inset 0px 0px 42px rgba(6, 197, 12, 0.6);
          }
        }

        .honey {
          background: url(../images/bg-live-item-9.svg);
        }
      }
    }
  }

  &-offer {
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(to top, #060029 0%, rgba(7, 0, 29, 0.8) 55.22%, #04001a 100%);

    @media (max-width: 480px) {
      flex-direction: column;
    }
    &-item {
      width: 33.3%;
      padding: 15px 28px 20px 28px;
      cursor: pointer;
      position: relative;

      @media (max-width: 640px) {
        padding: 15px 15px 20px 15px;
      }
      @media (max-width: 480px) {
        width: calc(100% - 4px);
      }
      &:before {
        @extend %before-after;
        background: linear-gradient(#310355 0%, rgba(75, 105, 255, 0.64) 0.12%, #130140 100%);
        border: 2px solid #4b69ff;
        border-bottom: 4px solid #4b69ff;
        box-shadow: 0px 0px 64px rgba(89, 6, 187, 0.28);
        width: 100%;
        height: calc(100% - 6px);
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 0.5s;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }

      .in-case-item {
        width: 100%;
        position: relative;
        margin: 0;

        .in-case-item-name,
        .in-case-item-desc {
          font-size: 14px;
          color: #d6ddff;
          text-shadow: 0px 0px 8px #4b69ff;
        }
        &[data-rar] {
          .img {
            background-image: linear-gradient(to bottom, rgba(6, 197, 12, 0.12), rgba(6, 197, 12, 0.5));
            box-shadow: 0 0 28px #06c50c, inset 0 0 28px #06c50c, inset 0 0 4px #06c50c;
            border-color: #06c50c;

            &:before {
              background-image: radial-gradient(circle 100px at 50% 129.77%, #06c50c 0, #06c50c 50%, transparent 80%);
            }

            &:after {
              border-color: #06c50c;
              box-shadow: inset 0px 0px 42px rgba(6, 197, 12, 0.6);
            }
          }

          .honey {
            background: url(../images/bg-live-item-9.svg);
          }
        }
      }
    }
  }

  .btns {
    padding: 28px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 12px;

      .btn {
        width: 100%;
        min-width: 100%;

        .text {
          flex-grow: 1;
          text-align: center;
        }
      }
    }
    .btn {
      display: inline-flex;
      height: 64px;
      border-radius: 300px;
      margin: 0 12px;

      .text {
        padding: 0;
      }
    }

    .btn-sale-cost {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      height: 28px;
      top: -18px;

      &.percent {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    .btn-withdraw {
      width: auto;
      align-items: center;
      padding-right: 20px;
      border: 2px solid #6595ff;
      transition: all 0.3s;
      position: relative;
      background: linear-gradient(to right, #0370ff 0%, #0652ff 100%);
      border: 4px solid #6595ff;
      font-size: 18px;
      letter-spacing: 0.05em;
      line-height: 24px;
      color: #fff;
      text-shadow: 0px 0px 6px #f7c0fe;

      .text {
        background: none;
        border-radius: 0;
        font-weight: bold;
        padding: 0 7px 0 0;
      }

      svg {
        margin-left: 12px;
        margin-right: 8px;
      }

      &:before {
        @extend %before-after;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid #6595ff;
        opacity: 0;
        border-radius: 30px;
        transition: all 0.3s;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.popup-approve-link {
  width: auto;
  background: #0d0329;
  background: -moz-linear-gradient(-45deg, #0d0329 0%, #250055 50%, #77007b 100%);
  background: -webkit-linear-gradient(-45deg, #0d0329 0%, #250055 50%, #77007b 100%);
  background: linear-gradient(135deg, #0d0329 0%, #250055 50%, #77007b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0d0329', endColorstr='#77007b', GradientType=1);
  box-shadow: 0px 0px 32px #d32ce6, inset 0px 0px 32px #d32ce6;

  .default-popup-wrap {
    padding: 20px 28px 28px 28px;
  }

  .profile-trade {
    margin-top: 0;
  }

  .profile-trade-link {
    margin-bottom: 18px;
  }

  .balance-title {
    padding-top: 0;
    padding-bottom: 13px;
    font-size: 24px;
    letter-spacing: 0.05em;
    line-height: 24px;
    text-align: left;
    color: #f7c0fe;
  }

  .balance-sub-title {
    font-size: 16px;
    line-height: 20px;
    color: #f7c0fe;
    border: 1px solid rgba(211, 44, 230, 0);
    text-shadow: 0px 0px 32px #d32ce6;
    margin-bottom: 19px;
  }

  .profile-trade-input {
    height: 88px;
    background: linear-gradient(to right, #2d0070 0%, #69057d 100%);
    padding: 4px;
    border-radius: 20px;
    margin-bottom: 40px;
    max-width: 100%;

    textarea {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: linear-gradient(#030a48 0%, #280450 100%);
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      color: #b36fff;
      border: 0;
      resize: none;
      padding: 15px 24px 20px 24px;
      font-family: "Roboto", sans-serif;
      overflow: hidden;

      &:focus {
        outline: none;
      }
    }
  }

  .btn {
    min-width: 322px;
    height: 64px;
    border-radius: 32px;
    background: rgba(211, 44, 230, 0.4);
    border: 4px solid #ec48ff;
    box-shadow: 0px 0px 16px #d32ce6;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #fff;
    text-shadow: 0px 0px 6px #f7c0fe;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;

    &:hover {
      background: rgba(211, 44, 230, 0.64);
      box-shadow: 0px 0px 16px #d32ce6;
    }
  }
}

.box-modal-fair-play {
  .default-popup-wrap {
    padding: 7px 28px 28px;
  }

  .balance-title {
    text-align: left;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 24px #d32ce6;
    color: #f7c0fe;
    padding: 20px 0;
  }

  .profile-trade {
    margin-top: 0;
  }
}

.box-modal-pay-ref {
  width: 100%;
  background: radial-gradient(530.41% 106.93% at 50% 32.25%, #190136 0%, #170044 32.8%, #640073 60.4%, #3d0041 100%);
  box-shadow: 0px 28px 64px rgba(211, 44, 230, 0.2), inset 0 0 64px #d32ce6;
  border-width: 4px;

  .default-popup-wrap {
    background: none;
  }

  .balance-title {
    padding: 23px 0 36px 0;
    color: #f7bffe;
    letter-spacing: 0;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 16px #d32ce6;
    font-weight: bold;
  }

  .pay-methods-items {
    padding: 0 28px;
    margin-bottom: 20px;
  }

  .pay-methods-item {
    width: calc(50% - 20px);

    &-hover {
      background: radial-gradient(105.31% 100% at 50% 100%, #6c0079 0%, rgba(36, 2, 57, 0.8) 53.83%, #2d008d 100%),
        linear-gradient(180deg, #310355 0%, #37005a 0.1%, #13003f 100%);
    }
  }

  .pay-ret-user {
    display: flex;
    max-width: 240px;
    margin: 20px auto 40px auto;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 8px solid #31004d;
      margin-right: 4px;
    }

    .name {
      font-size: 16px;
      line-height: 16px;
      color: #cccccc;
      margin-bottom: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 190px;
    }

    .cost {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      color: #d32ce6;
    }
  }

  .btn-pay-cost-wrap {
    margin-bottom: 40px;

    .btn-sale-tooltipe {
      left: auto;
      right: 0;
      position: absolute;
      top: -18px;
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, #330351 0%, #1d0457 100%);
      border-radius: 14px;
      font-size: 18px;
      letter-spacing: 0.1em;
      line-height: 24px;
      color: #eb4b4b;
      padding: 2px 8px;
      height: 28px;
      font-weight: bold;

      &:before {
        @extend %before-after;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        box-shadow: 0 0 3px 0 #ff6262;
        background-color: #ffc2c3;
        display: block;
        width: calc(100% - 12px);
        z-index: 2;
      }

      &:after {
        @extend %before-after;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        display: block;
        width: 100%;
        z-index: 2;
        background-image: linear-gradient(to right, #330351, #1d0457);
        margin-top: 2px;
      }
    }
  }

  .pay-form-method {
    background: linear-gradient(90deg, rgba(70, 15, 90, 0.12) 0%, rgba(152, 10, 125, 0.12) 100%);
    border-radius: 8px;
    margin: 0 28px 54px 28px;
    padding: 8px 44px 8px 20px;
    cursor: pointer;
    position: relative;

    &:before {
      @extend %before-after;
      background: rgba(19, 7, 44, 0.32);
      border-radius: 0px 8px 8px 0px;
      width: 48px;
      height: 100%;
      right: 0;
    }

    &:after {
      @extend %before-after;
      content: "arrow_drop_up" !important;
      font-size: 24px;
      line-height: 24px;
      color: rgba(230, 155, 255, 0.4);
      font-family: Material Icons;
      right: 0;
      width: 48px;
      height: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);
      transition: all 0.5s;
    }

    &-info {
      margin-right: auto;
    }

    &.active {
      &:after {
        transform: rotate(0deg);
      }
    }
  }

  .pay-form-input-wrap {
    display: flex;
  }

  .pay-form-input {
    margin: 0 28px 40px 28px;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    &:before {
      @extend %before-after;
      background: linear-gradient(90deg, #6d007a 0%, #370047 100%);
      top: calc(100% + 4px);
      height: 2px;
      width: 100%;
    }

    .icon {
      background: linear-gradient(300deg, #8214a8, #ff6ce3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 8px;
    }

    input {
      background: none;
      border: 0;
      color: #d32ce6;
      font-size: 16px;
      line-height: 19px;
      flex-grow: 1;

      &:focus {
        outline: none;
      }

      &::-webkit-input-placeholder {
        color: rgba(230, 155, 255, 0.4);
      }

      &::-moz-placeholder {
        color: rgba(230, 155, 255, 0.4);
      }

      &:-ms-input-placeholder {
        color: rgba(230, 155, 255, 0.4);
      }

      &:-moz-placeholder {
        color: pink;
      }
    }

    &-items {
      position: absolute;
      top: calc(100% + 8px);
      background: linear-gradient(180.17deg, #22083c 0.15%, #170044 101.22%);
      border-radius: 8px 6px 6px 8px;
      padding: 16px;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;
      max-height: 360px;
      overflow: auto;
      display: none;

      .pay-methods-item {
        width: calc(33.3% - 10px);
        margin: 0 5px 10px 5px;
      }

      &::-webkit-scrollbar {
        width: 10px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        border: 2px solid rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(214, 53, 187, 0.64);
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 1);
      }

      &.active {
        display: flex;
      }
    }

    &-cvv {
      max-width: 70px;
      text-align: center;
      margin-left: 0;
    }
  }
}

.profile-trade {
  margin-top: -17px;
  width: 100%;

  &-link {
    font-size: 16px;
    line-height: 20px;
    color: #d32ce6;
    margin-bottom: 16px;

    a {
      color: #ffc600;
      border-bottom: 1px solid rgba(255, 198, 0, 0.4);
      transition: all 0.5s;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  &-input {
    width: 100%;
    max-width: 440px;
    height: 44px;
    background: linear-gradient(to right, #490aa8 0%, #930aae 100%);
    border-radius: 72px;
    padding: 2px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      border-radius: 72px;
      background: linear-gradient(to right, #000746 0%, #370047 100%);
      padding: 0 121px 0 18px;
      border: 0;
      outline: none;
      font-size: 16px;
      line-height: 20px;
      color: rgba(123, 123, 123, 0.5);
    }

    button {
      position: absolute;
      right: 4px;
      top: 4px;
      height: 36px;
      width: 114px;
      height: 36px;
      border-radius: 18px;
      background: linear-gradient(to right, #7800a2 0%, #b111c4 100%);
      border: 2px solid #c20bd9;
      cursor: pointer;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;

      &:hover {
        background: linear-gradient(to right, #8e00bf 0%, #c50adb 100%);
        border: 4px solid #d90af3;
      }
    }
  }

  .trade-short-link {
    color: #4b69ff;
    border: 0;
    margin-left: 5px;
    margin-right: 8px;
  }

  .trade-edit {
    color: #5f4169;
    border-color: #5f4169;

    &:hover {
      color: #8847ff;
    }
  }
}

.popup-auth-special {
  max-width: 440px;
  background: radial-gradient(530.41% 106.93% at 50% 32.25%, #190136 0%, #170044 32.8%, #640073 60.4%, #3d0041 100%);
  border-width: 4px;

  .default-popup-wrap {
    position: relative;
    background: none;
  }

  &:before {
    @extend %before-after;
    width: 100%;
    height: 100%;
    background: url(../images/case-bg-5-popup.png) no-repeat 50% 17px;
    pointer-events: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:after {
    @extend %before-after;
    background: url(../images/case-bg-5-popup-2.png) no-repeat 50% 100%;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    pointer-events: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .img {
    margin-bottom: 15px;
  }

  .balance-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #f7bffe;
    text-shadow: -1px -1px 0 #c131e4, 1px -1px 0 #c131e4, -1px 1px 0 #c131e4, 1px 1px 0 #c131e4, 0px 0px 16px #d32ce6;
    letter-spacing: 0;
  }

  .auth-special-title {
    width: 232px;
    height: 50px;
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 24px #d32ce6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -50px auto 15px auto;
    z-index: 2;

    &:before {
      @extend %before-after;
      background: url(../images/bg-5-l.png) no-repeat;
      width: 46px;
      height: 50px;
      left: -46px;
      opacity: 0.6;
    }

    .center {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../images/bg-5.png) repeat-x 0 0;
      opacity: 0.6;
      z-index: -1;
    }

    &:after {
      @extend %before-after;
      background: url(../images/bg-5-r.png) no-repeat;
      width: 46px;
      height: 50px;
      left: auto;
      right: -46px;
      opacity: 0.6;
    }
  }

  .auth-special-title-seconds {
    width: 335px;
    height: 52px;
    display: flex;
    align-items: center;
    background: url(../images/case-bg-5-popup-4.png) no-repeat 0 0;
    margin: 0 auto 22px auto;
    color: #672900;
    padding: 0 53px;
    position: relative;

    &:before {
      @extend %before-after;
      content: "+" !important;
      font-weight: bold;
      font-size: 68px;
      line-height: 69px;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: -3px -3px 0 #d32ce6, 3px -3px 0 #d32ce6, -3px 3px 0 #d32ce6, 3px 3px 0 #d32ce6,
        0px 0px 68.5714px #d32ce6;
      left: auto;
      right: 0;
      top: -50px;
      z-index: 2;
    }

    .percent {
      font-weight: 900;
      font-size: 42px;
      line-height: 50px;
      color: #672900;
      margin-right: 9px;
    }

    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }

  .authorization {
    padding: 0 25px 35px 25px;
  }
}

// new
.popup-promo {
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 64px #d32ce6, inset 0 0 28px #d32ce6;

  .balance-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #f7bffe;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 4px #d32ce6,
      0px 0px 16px #d32ce6;
  }

  .img {
    img {
      width: 100%;
    }
  }

  .promo-text {
    .text {
      line-height: 16px;
      color: #d32ce6;
      text-align: left;
      margin-left: 20px;
      margin-right: 0;
      font-weight: normal;
    }

    .num {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      color: #ffffff;
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 16px #d32ce6,
        0px 0px 16px #d32ce6;
      margin-left: 11px;
      margin-right: 24px;
    }
  }

  .btn-pay {
    align-items: center;
    justify-content: center;
    width: 335px;
    height: 80px;
    text-decoration: none;

    .text {
      position: relative;
      transform: translate(0, 0);
      top: 0;
      left: 0;
      margin-left: 50px;
      margin-top: -20px;
    }

    .timer {
      font-weight: 900;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #6b2100;
      z-index: 2;
      margin-top: -22px;
      margin-left: 12px;
      z-index: 3;
    }
  }

  &-disclaimer {
    color: rgba(230, 155, 255, 0.4);
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.popup-promo-username {
  .img {
    img {
      max-height: 248px;
      width: auto;
      margin: -40px auto -30px auto;
      display: block;
    }
  }

  &.popup-promo {
    .promo-text {
      .text {
        margin-left: 37px;
      }

      .num {
        margin-right: 37px;
      }
    }
  }
}

.popup-promo-nomoney {
  .img {
    margin-top: -30px;
    width: calc(100% + 50px);
    transform: translateX(-25px);
  }

  .promo-text {
    .num {
      margin-right: 40px;
      margin-left: -20px;
    }

    .text {
      margin-left: 36px;
    }
  }
}

.popup-promo-lucky {
  .balance-title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 12px;
    padding-bottom: 5px;
  }

  .balance-title-sub {
    font-size: 16px;
    line-height: 16px;
    color: #d32ce6;
    text-align: center;
  }

  .img {
    padding-top: 35px;
    margin-top: -15px;

    img {
      height: 178px;
      width: auto;
      margin: 0 auto;
      display: block;
    }
  }

  .default-popup-wrap {
    background: none;
  }

  .popup-promo-disclaimer {
    margin-bottom: 0;
  }

  .promo-content {
    position: relative;
    padding-top: 15px;
    padding-bottom: 20px;

    &:before {
      @extend %before-after;
      background: radial-gradient(26.9% 6198000.36% at 50% 50.24%, #31015c 0%, rgba(113, 11, 134, 0.24) 100%);
      box-shadow: 0px 8px 10px 3px rgba(53, 3, 95, 0.5);
      top: -2px;
      height: 4px;
      width: 100%;
    }

    &:after {
      @extend %before-after;
      background: url(../images/case-bg-5-popup-2.png) no-repeat 50% 100%;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.12;
      pointer-events: none;
      transform: scale(-1);
    }
  }
}

.popup-promo-lucky-men {
  .default-popup-wrap {
    position: relative;

    &:before {
      @extend %before-after;
      width: 100%;
      height: 259px;
      background: url(../images/case-bg-5-popup.png) no-repeat 50% -78px;
      pointer-events: none;
      margin-top: 3px;
    }

    .img {
      margin-top: -6px;
    }
  }

  .balance-title {
    font-size: 24px;
    line-height: 24px;
  }

  .img {
    padding-top: 0px;

    img {
      height: 204px;
    }
  }
}

.popup-promo-case {
  .balance-title {
    padding-bottom: 0;
  }

  .balance-title-sub {
    color: #f7c0fe;
    text-shadow: 0px 0px 32px rgba(247, 192, 254, 0.5);
  }

  .default-popup-wrap {
    &:before {
      height: 100%;
      background-position: 50% 28px;
    }
  }

  &.popup-promo-lucky {
    background: linear-gradient(133.83deg, #0d0329 1.96%, #250055 51.47%, #77007b 100%);

    &:before {
      background-position: 50% 52px;
    }

    .img {
      padding-top: 10px;
    }

    .promo-content {
      padding-top: 0px;
      margin-top: 9px;
    }

    .promo-content {
      &:before {
        display: none;
      }
    }
  }

  .img {
    img {
      height: 271px;
    }
  }

  .auth-special-title {
    margin-top: -75px;
    white-space: nowrap;
    z-index: 2;

    &:before,
    &:after {
      z-index: -1;
    }
  }

  .popup-promo-case-cost {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -14px;

    .old {
      height: 28px;
      background: linear-gradient(90deg, #330351 0%, #1d0457 100%);
      border-radius: 14px;
      padding: 4px 8px;
      font-weight: bold;
      font-size: 18px;
      color: #eb4b4b;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 4px;

      &:before {
        @extend %before-after;
        height: 2px;
        width: calc(100% - 16px);
        background: #ffc2c3;
        box-shadow: 0px 0px 3px #ff6262;
        border-radius: 1px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -1px;
        z-index: 2;
      }

      &:after {
        @extend %before-after;
        height: 2px;
        width: calc(100% - 16px);
        background: linear-gradient(90deg, #330351 0%, #1d0457 100%);
        border-radius: 1px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0;
      }
    }

    .cost {
      min-width: 85px;
      border-radius: 21px;
      line-height: 32px;
      padding: 0 11px 0 14px;
      display: inline-flex;
      font-size: 18px;
      font-weight: 500;
      height: 36px;
      letter-spacing: 0.05em;
      text-decoration: none;
      text-transform: uppercase;
      border: 2px solid #f7c0fe;
      filter: drop-shadow(0px 0px 3px #d32ce6) drop-shadow(0px 0px 2px #d32ce6) drop-shadow(0px 0px 24px #d32ce6);
      box-shadow: none;
      color: #d32ce6;
      background: rgba(19, 7, 44, 0.8);
      z-index: 2;
      margin: 0 4px;
    }
  }
}

.popup-promo-secret-case {
  .balance-title {
    margin-bottom: 12px;
    padding-bottom: 0;
  }

  .balance-title-sub {
    font-size: 16px;
    line-height: 16px;
    color: #d32ce6;
    text-align: center;
    margin-bottom: 10px;
  }

  .img {
    margin-top: 0;

    img {
      height: 200px;
      width: auto;
      margin: 0 auto;
      display: block;
    }
  }
}

.popup-horiz {
  box-shadow: 0 0 64px #d32ce6, inset 0 0 28px #d32ce6;

  &.popup-promo-case.popup-promo-lucky {
    background: linear-gradient(133.83deg, #0d0329 1.96%, #250055 51.47%, #77007b 100%);
  }

  &.popup-auth-special:before {
    background-repeat: repeat-x;
    background-position: 50% -89px;
  }

  &.popup-auth-special:after {
    background-repeat: repeat-x;
    height: 160px;
  }

  .promo-content:after {
    background: none;
  }

  &.popup-promo-lucky {
    .img {
      padding-top: 40px;
      width: 100%;
      transform: translateX(0);
    }
  }
}

.popup-promo-horiz {
  display: flex;

  .balance-title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 40px;
    text-align: left;
    padding-left: 24px;
  }

  .auth-special-title {
    margin-top: 0;
    margin-left: 70px;
    margin-bottom: 1px;
    width: auto;
    display: inline-flex;

    &:before,
    &:after,
    .center {
      opacity: 1;
    }

    .center {
      display: flex;
      align-items: center;
      z-index: 2;
      width: auto;
      position: relative;
    }

    &-seconds {
      margin-left: 56px;

      &:before {
        right: auto;
        left: -28px;
        top: -35px;
      }
    }
  }

  .balance-title-sub {
    margin-bottom: 9px;
    text-align: left;
    padding-left: 24px;
  }

  &-right {
    margin: -40px 0 0 -40px;

    .popup-promo-case-cost {
      margin-top: -100px;
    }

    .img {
      img {
        pointer-events: none;
      }
    }
  }
}

.popup-promo-horiz-wheele {
  border-width: 6px;

  .balance-title {
    margin-bottom: 8px;
  }

  .auth-special-title-seconds {
    width: 393px;
    background-size: 100% 100%;
    margin-left: -35px;
    margin-bottom: 14px;

    &:before {
      display: none !important;
    }
  }

  .popup-promo-disclaimer {
    text-align: left;
    padding-left: 26px;
    margin-top: 0;
    color: rgba(230, 155, 255, 0.8);
  }

  .img {
    margin-bottom: 4px;
    pointer-events: none;

    &:before {
      margin: -20px 0 0 0;
      width: 312px;
      height: 312px;
    }

    img {
      width: 281px;
      height: 342px;
      margin: -13px 0 -154px 60px;
    }
  }

  .popup-promo-horiz-right {
    margin: -30px 0 0 -177px;
  }

  .promo-content {
    padding-bottom: 0;
  }

  &.popup-horiz.popup-auth-special:after {
    display: none;
  }
}

.popup-promo-horiz-lucky {
  .balance-title-sub {
    color: #d32ce6;
  }

  .popup-promo-horiz {
    margin-bottom: -20px;
  }

  &.popup-horiz.popup-auth-special:before {
    background-position: 50% -120px;
  }

  .img {
    width: 100%;
    transform: translateX(0);
    padding-top: 35px !important;

    img {
      width: 282px;
      height: auto;
      margin: -6px 0 -185px 35px;
    }

    &:before {
      display: none !important;
    }
  }

  .popup-promo-disclaimer {
    color: rgba(230, 155, 255, 0.4);
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .box-modal_close {
    z-index: 2;
  }
}

.box-modal-fair-play-history {
  width: 100%;
  background: linear-gradient(135deg, rgba(13, 3, 41, 1) 0%, rgba(37, 0, 85, 1) 50%, rgba(119, 0, 123, 1) 100%);

  .default-popup-wrap {
    padding: 0 8px;
  }

  .balance-title {
    border-bottom: 2px solid rgba(211, 44, 230, 0.2);
    margin: 0 -8px 8px -8px;
    padding: 20px 28px;
  }

  .history-fair {
    min-height: 398px;
    max-height: 398px;
    overflow: auto;
    margin-right: -4px;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(211, 44, 230, 0.3);
      border-right: 2px rgba(0, 0, 0, 0.3) solid;
      border-left: 2px rgba(0, 0, 0, 0.3) solid;
      border-top: 2px rgba(0, 0, 0, 0.3) solid;
      border-bottom: 2px rgba(0, 0, 0, 0.3) solid;
      background-clip: padding-box;
    }
  }

  .history-fair-table {
    margin-bottom: 20px;
    margin-right: 4px;

    &-head {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: rgba(123, 123, 123, 0.5);
      padding: 16px 20px;
      border-radius: 18px 18px 0px 0px;
      background: rgba(12, 1, 13, 0.4);
      border: 2px solid rgba(211, 44, 230, 0.2);

      & > :first-child {
        min-width: 132px;
        width: 132px;
        margin-right: 20px;
      }
    }

    &-body {
      font-size: 16px;
      line-height: 24px;
      border: 2px solid rgba(211, 44, 230, 0.1);

      @media (max-width: 420px) {
        overflow: auto;
        width: 400px;
      }
      .history-fair-table-row {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border-bottom: 2px solid rgba(211, 44, 230, 0.1);

        & > :first-child {
          min-width: 132px;
          width: 132px;
          margin-right: 20px;
          color: #b36fff;
        }

        & > :last-child {
          color: rgba(255, 255, 255, 0.8);
          word-break: break-word;
        }
      }
    }
  }
}
.popup-wheel-prize {
  border-width: 6px;
  width: 364px;
  background: linear-gradient(133.83deg, #0d0329 1.96%, #250055 51.47%, #77007b 100%);

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    .btn-pink.btn-big {
      flex-direction: column;
    }
  }
  &:before {
    @extend %before-after;
    top: 0;
    background: url(../images/popup-wheel-bg.png) no-repeat 3px 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .img {
    position: relative;
    margin: -67px 0 -40px 0;

    &:before {
      @extend %before-after;
      background: url(../images/case-win-light.png) no-repeat;
      background-size: contain;
      width: 346px;
      height: 360px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 304px;
      height: 304px;
      margin: 0 auto;
      display: block;
    }
  }

  .popup-promo-disclaimer {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #f7c0fe;
    margin-bottom: 18px;
  }

  .balance-btns {
    padding: 0 23px 23px 23px;
  }

  .timer {
    display: flex;
    margin-left: 5px;
    color: rgba(230, 155, 255, 0.4);

    span {
      float: none;
      color: #fff;
      font-weight: 700;

      span {
        color: #eb4b4b;
      }
    }
  }
}
.modal {
  @media (max-width: 768px) {
    width: calc(100% - 40px) !important;
  }
}
.modal[data-modal="WinDemoModal"] {
  .box-modal {
    padding: 28px 0 0 0;

    .login-btns {
      margin: 0 auto;
    }
  }
}
.modal-blue {
  width: auto;
  margin: -3px;
  background: radial-gradient(530.41% 106.93% at 50% 32.25%, #190136 0%, #170044 32.8%, #640073 60.4%, #3d0041 100%);
  border: 2px solid rgba(136, 71, 255, 0.16);
  border-top: 0;
  box-shadow: 0px 20px 28px #13072c, 0px 28px 64px rgba(211, 44, 230, 0.2);
  border-radius: 28px;
  padding: 28px 0;

  .disclaimer {
    color: rgba(230, 155, 255, 0.8);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-top: 16px;
  }

  .box-modal_close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(136, 71, 255, 0.4);
    background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
    border: 2px solid rgba(136, 71, 255, 0.4);
    box-shadow: 0px 0px 28px rgba(136, 71, 255, 0.4);
    transition: all 0.3s;
    z-index: 2;

    .icon {
      font-size: 30px;
      line-height: 30px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(136, 71, 255, 0.2), rgba(136, 71, 255, 0.2)),
        linear-gradient(90deg, #1c1132 0%, #120929 100%);
      border: 4px solid #8847ff;
      color: #8847ff;
      transform: rotate(90deg);
    }

    &:active {
      background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
      border: 2px solid rgba(136, 71, 255, 0.2);

      .icon {
        color: rgba(136, 71, 255, 0.2);
      }
    }
  }

  &:before {
    @extend %before-after;
    width: 100%;
    height: 200px;
    background: url(../images/popup-bonus-open-5-img-3.svg) no-repeat 0 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  &-title {
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: -1px -1px 0 #8847ff, 1px -1px 0 #8847ff, -1px 1px 0 #8847ff, 1px 1px 0 #8847ff, 0px 0px 80px #8847ff,
      0px 0px 24px rgba(136, 71, 255, 0.64);
    margin-bottom: 28px;
  }

  .popup-img-width-full {
    width: calc(100% + 4px);
    height: auto;
    display: block;
    margin-left: -2px;
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-top: -25px;
    margin-bottom: 44px;

    &:before {
      @extend %before-after;
      width: 472px;
      height: 64px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -5px;
      background: url(../images/popup-bonus-open-5-img-2.svg) no-repeat 0 0;
      z-index: -1;
    }

    &-num {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0px 0px 80px #d32ce6,
        0px 0px 24px rgba(211, 44, 230, 0.64);
      margin-right: 20px;
    }

    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #f7dbff;
    }

    &.revers {
      flex-direction: row-reverse;

      .modal-blue-text-num {
        margin: 0 0 0 20px;
      }
    }
  }

  &-btn-wrap {
    padding: 0 28px;

    @media (max-width: 480px) {
      padding: 0 8px;
    }
    hr {
      height: 2px;
      background: radial-gradient(50% 33100% at 50% 100%, rgba(136, 71, 255, 0.5) 39.49%, rgba(136, 71, 255, 0) 100%);
      opacity: 0.5;
      margin: 12px 0;
      border: 0;
    }

    .btn-yellow {
      width: 100%;
    }
  }

  &-case {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    margin-top: -28px;
    margin-bottom: 20px;

    &:before {
      @extend %before-after;
      background: url(../images/money-shadow.svg) no-repeat 50% 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 412px;
      height: 412px;
      background-size: 100%;
      z-index: -1;
      pointer-events: none;
    }

    img {
      width: 100%;
      max-width: 304px;
    }
  }

  .modal-blue-title-sec {
    color: rgba(230, 155, 255, 0.80);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

.modal-blue-not-wheel {
  .modal-blue-title {
    margin-bottom: 12px;
  }

  .modal-blue-case {
    margin-top: 0;
  }
}

.modal-blue-demo-game {
  background: radial-gradient(988.33% 88.16% at 50% 11.84%, #1c0057 0%, #18004a 38.62%, #3a0184 69.13%, #160041 100%);
  border: 2px solid rgba(136, 71, 255, 0.16);
  box-shadow: 0px 20px 28px #13072c, 0px 28px 64px rgba(211, 44, 230, 0.2);

  &:before {
    background: url(../images/popup-demo-game-cell-bg.svg) no-repeat 0 0;
    height: 295px;
  }

  .modal-blue-title {
    font-size: 24px;
    line-height: 24px;
    color: #f7bffe;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0 0 16px #d32ce6;
    margin-bottom: 10px;
  }

  .demo-prize-title {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 16px;
    color: #d32ce6;
    text-align: center;
  }

  .in-case-item {
    width: calc(100% - 56px);
    margin: 0 auto 54px auto;
    height: 116px;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
    }
    .in-case-info {
      color: #fff;
      bottom: 12px;
      left: 12px;
    }

    .img {
      margin-bottom: 14px;
    }
  }

  .btn-buy {
    margin: 0 auto 24px auto;
    width: calc(100% - 56px);

    @media (max-width: 480px) {
      width: calc(100% - 20px);
    }
    .icon {
      @media (max-width: 480px) {
        font-size: 22px;
      }
    }
    .btn-buy-text {
      margin: 0 auto;
      padding: 0 20px;
      white-space: nowrap;

      @media (max-width: 480px) {
        font-size: 16px;
        padding: 0 10px;
      }
    }
    .btn-buy-cost {
      @media (max-width: 480px) {
        padding: 0 6px;
        font-size: 16px;
      }
    }

    &.disabled {
      color: rgba(255, 255, 255, 0.32);
      background: rgba(136, 71, 255, 0.2);
      box-shadow: inset 0 0 0 4px rgba(136, 71, 255, 0.2);
      pointer-events: none;

      .btn-buy-text {
        text-shadow: none;
      }

      .btn-buy-cost {
        background: rgba(136, 71, 255, 0.2);
        color: rgba(255, 255, 255, 0.32);
        text-shadow: 0px 0px 6px rgba(211, 44, 230, 0.0001);
      }
    }
  }

  hr {
    background: radial-gradient(50% 16000% at 50% 100%, rgba(229, 216, 255, 0.4) 0%, rgba(32, 8, 66, 0.0001) 100%);
    opacity: 0.4;
    display: block;
    width: calc(100% - 56px);
    height: 2px;
    margin: 0 auto 24px auto;
    border: 0;
  }

  .cool-text {
    color: #f7dbff;
    background: url(../images/bg-cool-text.svg) no-repeat 0 0;
    width: 388px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
    position: relative;
    z-index: 3;

    @media (max-width: 440px) {
      width: 100%;
    }
  }

  .open-cases-btns {
    width: calc(100% - 36px);
    margin: 0 auto 24px auto;

    .advantages-sign-in-steam {
      width: 184px;
      height: 60px;
      margin-top: 8px;
      background: none;

      &:before {
        background: url(../images/icon-auth-steam-empty-small.svg) no-repeat 0 0;
        width: 184px;
        height: 61px;
        background-size: contain;
      }

      &:after {
        background: url(../images/icon-auth-steam-empty-small-hover.svg) no-repeat 0 0;
        width: 184px;
        height: 61px;
        background-size: contain;
      }

      .top {
        background: url(../images/icon-auth-steam-2-top-small.svg) no-repeat 0 0;
        width: 45px;
        height: 15px;
        top: -27px;
        left: 18px;
      }

      .bot-1 {
        background: url(../images/icon-auth-steam-2-bot-small.svg) no-repeat 0 0;
        width: 20px;
        height: 13px;
        bottom: -2px;
        left: 10px;
      }

      .bot-2 {
        background: url(../images/icon-auth-steam-2-bot-2-small.svg) no-repeat 0 0;
        width: 54px;
        height: 10px;
        bottom: 4px;
        left: 92px;
      }

      .small-text {
        font-size: 12px;
        line-height: 14px;
        padding-top: 0px;
        margin-bottom: -4px;
        padding-left: 26px;
      }

      .text {
        font-size: 19px;
        line-height: 28px;
        padding-left: 60px;
      }

      &:hover {
        filter: drop-shadow(0px 8px 64px rgba(255, 122, 0, 0.8));

        .top {
          top: -25px;
          left: 13px;
          transform: translate(-7px, -8px);
        }

        .bot-1 {
          transform: translate(-11px, 1px);
          bottom: -8px;
          left: 4px;
        }

        .bot-2 {
          transform: translate(-5px, 2px);
          bottom: -1px;
          left: 92px;
        }

        .small-text {
          transform: translate(-3px, -2px);
        }

        .text {
          transform: translate(-2px, -2px);
        }
      }
    }

    .advantages-sign-in-vk {
      width: 80px;
      height: 64px;
      filter: drop-shadow(0px 3px 80px rgba(0, 94, 175, 0.64));
      margin: -12px 0 0 10px;

      &:before {
        background: url(../images/icon-auth-vk-2-small.svg) no-repeat 0 0;
        width: 75px;
        height: 64px;
        background-size: contain;
      }

      &:after {
        background: url(../images/icon-auth-vk-2-small-hover.svg) no-repeat 0 0;
        width: 75px;
        height: 64px;
        background-size: contain;
      }

      .top {
        background: url(../images/icon-auth-vk-2-top-small.svg) no-repeat 0 0;
        width: 53px;
        height: 10px;
        top: -6px;
        left: 28px;
      }

      .bot-1 {
        background: url(../images/icon-auth-vk-2-bot-small.svg) no-repeat 0 0;
        width: 24px;
        height: 20px;
        left: -4px;
      }

      &:hover {
        filter: drop-shadow(0px 3px 24px rgba(0, 94, 175, 0.8)) drop-shadow(0px 3px 80px rgba(0, 94, 175, 0.8));

        .top {
          transform: translate(6px, -10px);
        }

        .bot-1 {
          transform: translate(-4px, 2px);
        }
      }
    }

    .advantages-sign-in-fb {
      width: 80px;
      height: 64px;
      filter: drop-shadow(0px 3px 24px rgba(14, 54, 255, 0.64)) drop-shadow(0px 3px 80px rgba(0, 36, 214, 0.64));
      margin: -3px 0 0 10px;

      &:before {
        background: url(../images/icon-auth-fb-2-small.svg) no-repeat 0 0;
        width: 80px;
        height: 64px;
      }

      &:after {
        background: url(../images/icon-auth-fb-2-small-hover.svg) no-repeat 0 0;
        width: 80px;
        height: 64px;
      }

      .top {
        background: url(../images/icon-auth-fb-2-top-small.svg) no-repeat 0 0;
        width: 69px;
        height: 49px;
        top: -22px;
        left: 8px;
      }

      .bot-1 {
        background: url(../images/icon-auth-fb-2-bot-small.svg) no-repeat 0 0;
        width: 31px;
        height: 21px;
        left: 46px;
        bottom: -10px;
      }

      &:hover {
        filter: drop-shadow(0px 3px 24px rgba(14, 54, 255, 0.8)) drop-shadow(0px 3px 80px rgba(0, 36, 214, 0.8));

        .top {
          transform: translate(3px, -6px);
        }

        .bot-1 {
          transform: translate(-1px, 0px);
        }
      }
    }

    .advantages-sign-in-google {
      filter: drop-shadow(0px 2.03875px 16.31px rgba(0, 148, 181, 0.64))
        drop-shadow(0px 2.03875px 54.3667px rgba(0, 148, 181, 0.64));
      margin-right: -18px;

      &:before {
        width: 70px;
        height: 64px;
        background: url(../images/icon-auth-google-2-small.svg) no-repeat 0 0;
        background-size: contain;
      }

      &:after {
        width: 73px;
        height: 64px;
        background: url(../images/icon-auth-google-2-small-hover.svg) no-repeat 0 0;
        background-size: contain;
      }

      .top {
        width: 22px;
        height: 15px;
        background: url(../images/icon-auth-google-2-top-small.svg) no-repeat 0 0;
        top: -3px;
        left: 22px;
      }

      .bot {
        width: 18px;
        height: 17px;
        background: url(../images/icon-auth-google-2-bot-small.svg) no-repeat 0 0;
        bottom: -3px;
        left: 10px;
      }

      &:hover {
        filter: drop-shadow(0px 2.80328px 22.4262px rgba(0, 148, 181, 0.8))
          drop-shadow(0px 2.80328px 74.7541px rgba(0, 148, 181, 0.8));

        .top {
          transform: translate(-6px, -4px);
        }

        .bot {
          transform: translate(-2px, 2px);
        }
      }
    }
  }

  .demo-warning {
    background: rgba(136, 71, 255, 0.2);
    border-radius: 8px;
    color: rgba(213, 185, 255, 0.5);
    font-size: 14px;
    line-height: 16px;
    width: calc(100% - 56px);
    margin: 0 auto;
    padding: 16px;
    position: relative;
    z-index: 3;
    overflow: hidden;

    @media (max-width: 480px) {
      width: calc(100% - 20px);
    }
    .icon {
      font-weight: 400;
      font-size: 96px;
      line-height: 96px;
      background: linear-gradient(0deg, #5119b8 0%, #8847ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      position: absolute;
      top: -15px;
      left: -19px;
      z-index: -1;
      opacity: 0.32;
    }

    &-title {
      font-weight: 700;
      color: #ffc600;
      margin-bottom: 8px;
    }
  }
}
.modal[data-modal="DistributionModal"] {
  @media (max-width: 900px) {
    width: calc(100% - 40px) !important;
  }
}
.popup-demo-free {
  min-height: 166px;
  background: radial-gradient(530.41% 106.93% at 50% 32.25%, #190136 0%, #170044 32.8%, #640073 60.4%, #3d0041 100%);
  border: none;
  box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.16), 0px 20px 28px #13072c, 0px 28px 64px rgba(211, 44, 230, 0.2);
  border-radius: 28px;
  padding: 0;
  margin: -3px;
  display: flex;
  width: auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  &:before {
    display: none !important;
  }

  &-user {
    min-height: 166px;
    background: rgba(0, 0, 0, 0.32);
    width: 412px;
    border-radius: 28px 0 0 28px;

    @media (max-width: 900px) {
      order: 1;
      width: 100%;
      border-radius: 0 0 26px 26px;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.5);
    padding: 22px 30px 0 30px;
    text-align: center;
    margin-bottom: 22px;
  }

  &-user-items {
    max-height: 503px;
    overflow: auto;
    width: calc(100% - 2px);

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(214, 53, 187, 0.64);
      border-radius: 8px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }

  &-user-items-row {
    display: flex;
    align-items: center;
    padding: 8px 30px;
    background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.04) 0%, rgba(211, 44, 230, 0) 100%);
    margin-bottom: 4px;
    cursor: pointer;

    @media (max-width: 480px) {
      padding: 8px 24px;
    }
    &-num {
      color: #a97aff;
      min-width: 33px;
      font-weight: 700;
    }

    .top-drop-item {
      box-shadow: none;
      margin: 0 20px 0 11px;
      width: 112px;
      height: 64px;
      min-width: 112px;

      @media (max-width: 480px) {
        margin: 0 8px 0 11px;
      }
      .drop-img {
        height: 100%;
        z-index: 3;
        position: relative;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        transition: all 0.3s;
      }

      .top-drop-item-name {
        margin: 6px 0 0 0;
      }

      .in-case-cost {
        top: 3px;
        right: 3px;
      }

      &:hover {
        .drop-img {
          margin-top: 0;
        }
      }
    }

    &:hover {
      background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.08) 0%, rgba(211, 44, 230, 0) 100%);

      .top-drop-item {
        .top-drop-item-hover {
          margin-top: 0;
          opacity: 1;
        }

        .drop-img {
          transform: translateY(-10px);
        }

        .in-case-cost {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &-prize {
    width: 412px;
    position: relative;

    @media (max-width: 900px) {
      width: 100%;
    }
    &:before {
      @extend %before-after;
      width: 100%;
      height: 200px;
      background: url(../images/popup-bonus-open-5-img-3.svg) no-repeat 0 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }

    &:after {
      @extend %before-after;
      height: 100%;
      width: 2px;
      background: linear-gradient(
        to bottom,
        rgba(229, 216, 255, 0) 0%,
        rgba(229, 216, 255, 0.4) 50%,
        rgba(229, 216, 255, 0) 100%
      );
      opacity: 0.4;
      top: 0;
    }

    .in-case-item {
      &.status-4 {
        .in-case-cost {
          background: linear-gradient(90deg, #490475 0%, #380b9d 100%), #c4c4c4;
          box-shadow: 0 0 0 2px #6804a1;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }

      .img {
        img {
          width: 200px;
          height: 200px;
          padding: 0;
        }
      }
    }

    &-title {
      font-weight: 900;
      font-size: 30px;
      line-height: 30px;
      color: #ffffff;
      text-shadow: -1px -1px 0 #ffc600, 1px -1px 0 #ffc600, -1px 1px 0 #ffc600, 1px 1px 0 #ffc600, 0px 0px 80px #ffc600,
        0px 0px 24px rgba(255, 198, 0, 0.64);
      text-transform: uppercase;
      text-align: center;
      margin: -10px 0 20px 0;
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffc600;
      margin-bottom: 16px;
    }

    .in-case-item {
      width: calc(100% - 56px);
      margin: 0 auto;

      @media (max-width: 480px) {
        width: calc(100% - 24px);
      }
      .in-case-info {
        margin-top: 13px;
        justify-content: center;
      }

      &:hover {
        .img > img {
          transform: translate(0, 0);
        }
      }
    }

    .page-timer {
      width: 356px;
      margin-bottom: 17px;

      @media (max-width: 480px) {
        width: 100%;
      }
      &:before {
        background: url(../images/page-timer-bg-small.svg) no-repeat 0 0;
        width: 365px;
        height: 111px;

        @media (max-width: 480px) {
          width: 365px;
          height: 111px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &-user-items-row-user {
    display: flex;
    align-items: center;

    &-ava {
      box-shadow: inset 0 0 0 2px #360153;
      transition: all 0.3s;
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 2px;
      margin-right: 12px;

      @media (max-width: 480px) {
        margin-right: 4px;
      }
      &:before {
        @extend %before-after;
        border: 2px solid #360153;
        transition: all 0.3s;
        pointer-events: none;
        width: 100%;
        height: 100%;
        z-index: 3;
        border-radius: 50%;
        box-sizing: border-box;
      }

      img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        border-radius: inherit;
      }

      .tooltipe {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 2px 8px;
        background: linear-gradient(90deg, #380b9d 0%, #490475 100%);
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        white-space: nowrap;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover {
        &:before {
          border-width: 4px;
        }

        .tooltipe {
          opacity: 1;
          margin-top: -2px;
        }
      }
    }

    &-name {
      font-size: 16px;
      line-height: 19px;
      color: #cccccc;
      max-width: 105px;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }

  &-steps {
    width: calc(100% - 56px);
    margin: 53px auto 32px auto;

    @media (max-width: 480px) {
      width: calc(100% - 24px);
    }
    &-item {
      display: flex;
      align-items: center;
      padding: 16px 0 16px 10px;
      background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.04) 0%, rgba(211, 44, 230, 0) 100%);
      margin-bottom: 4px;
      text-decoration: unset;

      &:hover {
        cursor: pointer;
        background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.08) 0%, rgba(211, 44, 230, 0) 100%);

        .popup-demo-free-steps-item-text-pink {
          color: #f7dbff;
          text-shadow: 0px 0px 36px #c131e4, 0px 0px 3px #c131e4;

          &:before {
            background: #f7dbff;
          }
        }
      }
      &.disabled {
        cursor: default;

        .popup-demo-free-steps-item-text-pink {
          opacity: 0.5;
        }

        &:hover {
          .popup-demo-free-steps-item-text-pink {
            color: #f7dbff;
            opacity: 0.5;
            text-shadow: 0px 0px 36px #c131e4, 0px 0px 3px #c131e4;
          }
        }
      }

      &.active {
        .popup-demo-free-steps-item-text-pink {
          color: #8847ff;

          &:before {
            background: #8847ff;
          }
        }

        .popup-demo-free-steps-item-link {
          pointer-events: none;
          border: 2px solid rgba(136, 71, 255, 0.24);
          background: none;
          box-shadow: none;

          .icon {
            color: rgba(136, 71, 255, 0.64);
          }
        }
      }

      &-text {
        font-size: 16px;
        flex-grow: 1;

        &-pink {
          position: relative;
          line-height: 24px;
          color: #d32ce6;
          padding-left: 15px;

          &:before {
            @extend %before-after;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: 9px;
            left: 0;
            background: #d32ce6;
          }

          span {
            color: #fff;
            margin-left: 5px;
          }

          .violet {
            color: #8847ff;
            font-style: normal;
          }
        }
      }

      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        min-width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(255, 198, 0, 0.2);
        box-shadow: inset 0 0 0 4px #ffc600, 0px 0px 16px rgba(255, 198, 0, 0.64);
        color: #fff;
        text-decoration: none;
        transition: box-shadow 0.3s;

        .icon {
          font-size: 32px;
          line-height: 32px;
          color: #ffffff;
        }

        &:hover {
          box-shadow: inset 0 0 0 6px #ffc600, 0px 0px 16px rgba(255, 198, 0, 0.64);
          background: rgba(255, 198, 0, 0.48);
        }

        &.replay {
          .icon {
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }
        }
      }
    }
  }

  .btn-yellow {
    width: calc(100% - 56px);
    margin: 0 auto 24px auto;
    display: block;

    @media (max-width: 480px) {
      width: calc(100% - 24px);
    }
    &.disabled {
      background: rgba(136, 71, 255, 0.2);
      border: 4px solid rgba(136, 71, 255, 0.2);
      box-shadow: none;
      color: rgba(255, 255, 255, 0.32);
      pointer-events: none;
    }

    &.disabled-done {
      color: #fff;
      display: flex;
      align-items: center;

      .icon {
        opacity: 0.32;
        color: #fff;
        font-size: 32px;
        line-height: 32px;
        margin-right: 16px;
      }

      .distribution-item-controls-counter {
        .icon {
          font-size: 16px;
          margin-right: 6px;
        }
      }

      span.text {
        flex-grow: 1;
      }
    }

    .distribution-item-controls-counter {
      position: absolute;
      top: -15px;
      right: -4px;
      font-size: 14px;
      color: rgba(230, 155, 255, 0.4);
      font-weight: 500;
    }
  }

  &-hash {
    padding: 0 28px 16px 28px;
    margin: -10px 0 0 0;
    font-size: 14px;
    line-height: 20px;
    color: rgba(230, 155, 255, 0.4);
    word-break: break-word;
    position: relative;

    &:before {
      @extend %before-after;
      width: 100%;
      height: 2px;
      top: 100%;
      left: 0;
      background: radial-gradient(50% 16000% at 50% 100%, rgba(229, 216, 255, 0.4) 0%, rgba(32, 8, 66, 0.0001) 100%);
      opacity: 0.2;
      margin-top: -1px;
    }
  }
  .open-cases-btns {
    .login-btns {
      margin: 0 auto;
    }
    @media (max-width: 480px) {
      flex-wrap: wrap;
      gap: 32px;
      margin-bottom: 30px;
    }
  }
}

.popup-demo-free-win {
  background: radial-gradient(496.05% 100% at 50% 0%, #270055 0%, #15003f 43.65%, #640073 70.21%, #300155 92.01%);

  &:before {
    background-size: 100%;
  }

  .popup-demo-free-prize-title {
    text-shadow: -1px -1px 0 #eb4b4b, 1px -1px 0 #eb4b4b, -1px 1px 0 #eb4b4b, 1px 1px 0 #eb4b4b, 0px 0px 80px #eb4b4b,
      0px 0px 24px rgba(235, 75, 75, 0.64);
  }

  .popup-demo-free-prize-text {
    color: #eb4b4b;
  }

  .in-case-item {
    margin-bottom: 54px;

    @media (max-width: 420px) {
      margin-bottom: 16px;
    }
  }

  .btn-sell {
    width: calc(100% - 56px);
    margin: 0 auto 16px auto;
    height: 64px;
    border-radius: 300px;

    @media (max-width: 420px) {
      width: calc(100% - 20px);

      .text {
        font-size: 14px;
      }
    }
    .text {
      margin: 0 auto;
    }

    .btn-sale-cost {
      background-image: linear-gradient(to right, #330351, #1d0457);
      padding: 2px 8px;
      height: 24px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      border-radius: 14px;

      &-wrap {
        top: -14px;
      }

      &:after {
        height: 2px;
        margin-top: 0px;
        border-radius: 1px;
      }
    }
  }

  .btn-withdraw {
    position: relative;
    text-decoration: none;

    .icon {
      width: 64px;
      height: 64px;
      background: linear-gradient(#0370ff 0%, #0652ff 100%);
      border: 2px solid #6595ff;
      border-radius: 50%;
      position: relative;
      transition: all 0.5s;
      margin: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      font-size: 32px;
      line-height: 32px;
      color: #fff;

      @media (max-width: 420px) {
        margin: 0;
      }
    }

    span {
      position: absolute;
      top: calc(100% - 2px);
      left: 50%;
      transform: translate(-50%, -10px);
      height: 20px;
      border-radius: 4px;
      background: #0370ff;
      background: -moz-linear-gradient(left, #0370ff 0%, #0652ff 100%);
      background: -webkit-linear-gradient(left, #0370ff 0%, #0652ff 100%);
      background: linear-gradient(to right, #0370ff 0%, #0652ff 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      white-space: nowrap;
      padding: 0 8px;
      opacity: 0;
      transition: all 0.5s;
      z-index: 1;
    }

    &:hover {
      .icon {
        border: 4px solid #6595ff;
      }

      span {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }

  .btns-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 56px);
    margin: 0 auto;
    position: relative;
    padding: 18px 0 28px 0;

    &:before {
      @extend %before-after;
      width: calc(100% - 56px);
      height: 2px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: radial-gradient(50% 16000% at 50% 100%, rgba(229, 216, 255, 0.4) 0%, rgba(32, 8, 66, 0.0001) 100%);
      opacity: 0.4;
    }

    & > * {
      margin: 0 12px;

      @media (max-width: 420px) {
        margin: 0 4px;

        .btn-withdraw {
          .icon {
            margin: 0;
          }
        }
      }
    }
  }

  .btn-back:not(.btn-contracts),
  .btn-home {
    .icon {
      border-color: #323c98;
      color: #4b69ff;
      background: linear-gradient(90deg, #1c1132 0%, #120929 100%);

      svg {
        opacity: 1;
      }
    }

    span {
      background: #3d59e5;
      background: -moz-linear-gradient(left, #3d59e5 0%, #1b2e8f 100%);
      background: -webkit-linear-gradient(left, #3d59e5 0%, #1b2e8f 100%);
      background: linear-gradient(to right, #3d59e5 0%, #1b2e8f 100%);
    }

    &:hover {
      .icon svg path {
        fill: #4b69ff;
      }
    }
  }
}

.modal-blue-sec {
  .modal-blue-title {
    margin-bottom: 16px;
    -webkit-text-stroke: 1px #d32ce6;
    text-shadow: 0px 0px 80px #d32ce6, 0px 0px 24px rgba(211, 44, 230, 0.64);
  }

  .modal-blue-title-sec {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #d32ce6;
    margin-bottom: 23px;
  }

  .popup-img-width-full {
    margin-bottom: -20px;
  }
}

.popup-breezy-ranking {
  @import "../components/profile-stats";

  .profile-stats {
    justify-content: center;
    padding: 0 5px;
    margin-bottom: 24px;

    &-item {
      width: 50%;
      padding: 0 28px;
    }

    & > :first-child {
      padding-left: 39px;
      position: relative;

      &:before {
        @extend %before-after;
        width: 1px;
        height: 64px;
        background: radial-gradient(50% 50% at 50% 50%, #b0c3d9 0%, rgba(176, 195, 217, 0.0001) 100%);
        mix-blend-mode: normal;
        opacity: 0.4;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
      }
    }

    &-num {
      margin-bottom: 7px;
      font-size: 24px;
    }
  }

  .popup-demo-free-prize-title {
    padding-top: 36px;
    -webkit-text-stroke: 1px #d32ce6;
    text-shadow: 0px 0px 80px #d32ce6, 0px 0px 24px rgba(211, 44, 230, 0.64);
  }

  .page-timer {
    margin-bottom: 28px;
  }

  @import "../components/in-case-item-pos-count";

  .in-case-item {
    margin-bottom: 64px;

    .img {
      height: 130px;
    }
  }
}

.popup-demo-free.popup-demo-free-win {
  background: radial-gradient(530.41% 106.93% at 50% 32.25%, #190136 0%, #170044 32.8%, #640073 60.4%, #3d0041 100%);

  .popup-demo-free-user-items {
    max-height: 340px;
  }
}

.popup-demo-user-link {
  height: 80px;
  background: rgba(211, 44, 230, 0.12);
  border: 2px solid rgba(193, 49, 228, 0.24);
  border-radius: 64px;
  margin: 40px 28px 40px 28px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: rgba(230, 155, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s;

  &-ava {
    border: 4px solid rgba(36, 0, 40, 0.4);
    filter: drop-shadow(0px 0px 240px rgba(211, 44, 230, 0.64));
    border-radius: 50%;
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  &-name {
    margin-right: 20px;
  }

  &-btn {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(230, 155, 255, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
      font-weight: 400;
      font-size: 32px;
      line-height: 32px;
      color: rgba(230, 155, 255, 0.4);
    }
  }

  &:hover {
    .popup-demo-user-link-btn {
      background: linear-gradient(0deg, rgba(230, 155, 255, 0.12), rgba(230, 155, 255, 0.12)), #170041;

      .icon {
        color: #f7dbff;
      }
    }

    .tooltip-block {
      opacity: 1;
      z-index: 1;
      white-space: nowrap;
      background: rgba(230, 155, 255, 0.08);
    }
  }
}
.modal-blue[data-modal="TradeUrlSave"] {
  padding: 28px;

  .FakeInput > :last-child div {
    color: transparent !important;
    z-index: 1;
  }

  &:before {
    display: none;
  }

  .modal-blue-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: rgba(230, 155, 255, 0.8);
    text-transform: none;
    text-shadow: none;
    text-align: left;
    margin-bottom: 32px;
  }

  .input-gradient {
    margin-bottom: 20px;
    width: 100%;

    & > :first-child {
      color: #3b1674;
    }
  }

  .profile-trade {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .link-where {
    @extend %link-where;
  }
}

.modal-profile-prize {
  .modal-blue-title {
    ont-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff, 1px 1px 0 #4b69ff, 0px 0px 80px #4b69ff,
      0px 0px 24px rgba(75, 105, 255, 0.64);
    margin-bottom: 28px;
  }

  .in-case-item {
    margin-bottom: 42px;
  }

  .more-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(230, 155, 255, 0.8);
    padding: 0 28px;
    margin-bottom: 16px;

    span {
      color: #eb4b4b;
    }

    a {
      color: #4b69ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btn-buy {
    background: linear-gradient(#019101 0%, #008200 100%);
    box-shadow: inset 0 0 0 4px #06c50c, 0px 0px 8px rgba(6, 197, 12, 0.4);

    &:hover {
      box-shadow: inset 0 0 0 6px #00f308, 0px 0px 8px rgba(6, 197, 12, 0.8);

      .btn-buy-text {
        text-shadow: -1px -1px 0 #06c50c, 1px -1px 0 #06c50c, -1px 1px 0 #06c50c, 1px 1px 0 #06c50c, 0px 0px 8px #06c50c;
      }
    }
  }

  .btn-blue {
    border: 0;
    background: linear-gradient(90deg, #0370ff 0%, #0652ff 100%);
    box-shadow: inset 0 0 0 4px #6595ff, 0px 0px 8px rgba(101, 149, 255, 0.4);

    &:hover {
      box-shadow: inset 0 0 0 6px #6595ff, 0px 0px 8px rgba(101, 149, 255, 0.8);

      .btn-buy-text {
        text-shadow: -1px -1px 0 #6595ff, 1px -1px 0 #6595ff, -1px 1px 0 #6595ff, 1px 1px 0 #6595ff, 0px 0px 8px #6595ff;
      }
    }

    &[disabled] {
      background: rgba(75, 105, 255, 0.08);
      box-shadow: inset 0 0 0 4px rgba(75, 105, 255, 0.2);
      color: rgba(75, 105, 255, 0.4);
      text-shadow: none;

      .icon {
        color: rgba(75, 105, 255, 0.4);
      }

      .btn-buy-text {
        text-shadow: none;
      }
    }
  }
}

.modal-skin {
  padding: 28px;
  background: linear-gradient(135deg, #0d0329 0%, #250055 50.13%, #77007b 100%);
  box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.16);
  border: none;

  .flag-dropdown {
    color: black;
  }
  
  &.modal-blue {
    &:before {
      display: none;
    }
  }

  .modal-blue-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    text-transform: uppercase;
    color: #f7bffe;
    text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6, 1px 1px 0 #d32ce6, 0 0 16px #F7BFFE;
    margin-bottom: 24px;
    text-align: left;
  }

  .modal-blue-title-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.42857;
    color: rgba(213, 185, 255, 0.8);
    margin-bottom: 24px;
  }

  .modal-blue-btn-wrap {
    padding: 0;
  }

  .btn-violet {
    padding: 10px 22px;
    height: 64px;
    box-shadow: inset 0 0 0 4px #d32ce6, 0 0 16px 0 rgba(211, 44, 230, 0.64);
    background: rgba(211, 44, 230, 0.2);
    border-radius: 32px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
    transition: all 0.3s;
    position: relative;
    width: 100%;

    &:hover {
      background: rgba(211, 44, 230, 0.48);
      box-shadow: inset 0 0 0 6px #d32ce6, 0 0 16px 0 rgba(211, 44, 230, 0.64);
    }

    &.disabled {
      background: rgba(136, 71, 255, 0.2);
      pointer-events: none;
      box-shadow: inset 0 0 0 4px rgba(136, 71, 255, 0.2);
      color: rgba(255, 255, 255, 0.32);
      text-shadow: none;
    }
  }

  .modal-skin-phones {
    display: flex;
    align-items: center;
    gap: 4px;
    background: linear-gradient(135deg, rgba(102, 4, 148, 0.22) 1.56%, rgba(102, 4, 148, 0.5) 27.92%, rgba(178, 3, 145, 0.52) 74.09%, rgba(178, 3, 145, 0.34) 100%);
    padding: 16px 28px;
    margin: 0 -28px 24px -28px;
  }

  .phones-change {
    width: 72px;
    height: 40px;
    position: relative;
    @include flex(row, center, false, false);
    gap: 8px;
    padding: 12px 8px 12px 10px;
    cursor: pointer;
    border-radius: 4px;

    @include before {
      border-radius: 4px;
      border: 2px solid transparent;
      background: linear-gradient(45deg, #F047FF, #8847FF) border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      opacity: .08;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    @include after {
      top: 100%;
      height: 8px;
      left: 0;
      width: 100%;
    }

    img {
      border-radius: 2px;
      display: block;
      width: 20px;
      height: auto;
    }

    .icon {
      color: rgba(230, 155, 255, .4);
      transition: all .3s;
    }

    &:hover {
      background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);

      &:before {
        opacity: 0;
      }

      .icon {
        transform: rotate(180deg);
      }

      .phones-change-dropdown {
        opacity: 1;
        pointer-events: all;
      }
    }

    &-input {
      position: relative;

      @include before {
        border-radius: 4px;
        border: 2px solid transparent;
        background: linear-gradient(45deg, #E69BFF, rgba(230, 155, 255, .4)) border-box;
        -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        opacity: .08;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        pointer-events: none;
      }

      input {
        width: 100%;
        height: 40px;
        padding: 0 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.75;
        color: rgba(230, 155, 255, 0.8);
        background: linear-gradient(180deg, #2d0042 0%, rgba(40, 0, 65, 0) 100%);
        display: block;
        border: 0;
        border-radius: 4px;

        &:focus {
          outline: none;
        }
      }
    }

    &-error {
      color: rgba(230, 155, 255, 0.8);
    }

    &-dropdown {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      width: 270px;
      height: 164px;
      border-radius: 16px 6px 6px 16px;
      padding: 10px 0 0 0;
      background-color: #13072C;
      background-image: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
      z-index: 2;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
      opacity: 0;
      pointer-events: none;
      transition: all .3s;

      &-item {
        padding: 2px 10px;
        @include flex(row, center, false, false);
        font-size: 16px;
        gap: 7px;
        transition: all .3s;

        &:hover,
        &.active {
          color: #f067ff;
          background: rgba(211, 44, 230, 0.16);
        }
      }

      &::-webkit-scrollbar {
        width: 14px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(193, 49, 228, .64);
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0.24);
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.24);
        border-radius: 6px;
      }
    }
  }
}
