@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@0;1&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Material+Icons:wght@400&display=swap&display=swap');

@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.7.0);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0)
      format('embedded-opentype'),
    url(../fonts/fontawesome-webfont.woff2?v=4.7.0) format('woff2'),
    url(../fonts/fontawesome-webfont.woff?v=4.7.0) format('woff'),
    url(../fonts/fontawesome-webfont.ttf?v=4.7.0) format('truetype'),
    url(../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular)
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS BroadBrush';
  src: url('../fonts/DSBroadBrush.eot');
  src: url('../fonts/DSBroadBrush.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DSBroadBrush.woff2') format('woff2'),
    url('../fonts/DSBroadBrush.woff') format('woff'),
    url('../fonts/DSBroadBrush.ttf') format('truetype'),
    url('../fonts/DSBroadBrush.svg#DSBroadBrush') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pangolin';
  src: url('../fonts/Pangolin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Knewave';
  src: url('../fonts/Knewave-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Russo One';
  src: url('../fonts/RussoOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'a_FuturaRoundDemi';
  font-style: normal;
  font-weight: 400;
  src: local('a_FuturaRoundDemi'), local('a_FuturaRoundDemi'),
    url(../fonts/a_futurarounddemi.ttf) format('truetype');
  font-display: swap;
}

body {
  font-family: 'Roboto', sans-serif;
}

.icon.material-icon,
.icon.material-icons {
  font-family: 'Material Icons';

  &[data-color='#3B1674'] {
    color: #3b1674;
  }

  &:before {
    content: attr(data-icon);
  }
}
