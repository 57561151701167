@import "~react-toastify/dist/ReactToastify.css";
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";
@import "~swiper/css/scrollbar";

@import "base/reset";
@import "base/typography";

@import "abstracts/extends";
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/buttons";
@import "abstracts/cover";

@import "untils/font-awesome.min";

@import "base/default";
@import "components/login-btns";
@import "components/popups";
@import "components/loader";
@import "components/live-drop";
@import "components/notify";
@import "components/wheel-btn";
@import "components/gift-skin";
@import "components/in-case-item";
@import "components/stream";
@import "components/loader";

@import "layout/header";
@import "layout/footer";
