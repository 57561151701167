.stream {
  width: 275px;
  height: 155px;
  position: fixed;
  right: 20px;
  top: 384px;
  background: linear-gradient(180deg, #1F0075 0%, #7C0081 100%);
  box-shadow: 0px 20px 28px #13072C, 0px 28px 64px rgba(211, 44, 230, 0.2), inset 0 0 0 4px rgba(211, 44, 230, 0.4);
  border-radius: 16px;
  cursor: pointer;
  z-index: 99;

  iframe {
    width: 275px;
    height: 155px;
    position: fixed;
    border-radius: 16px;
    z-index: 99;
  }

  img {
    opacity: .4;
    border-radius: 12px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    object-fit: cover;
    transform: translate(4px, 4px);
  }

  &:before {
    @extend %before-after;
    background-image: repeating-linear-gradient(
                    0deg,
                    transparent,
                    transparent 2px,
                    rgba(193, 49, 228, .2) 2px,
                    rgba(193, 49, 228, .2) 4px
    );
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 4px;
    top: 4px;
  }

  &-online {
    padding: 4px 10px 4px 7px;
    position: absolute;
    top: 4px;
    left: 4px;
    height: 24px;
    background: #080809;
    border: 1px solid #591B78;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    color: #F5412E;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid #ac0302;
      margin-right: 5px;
      animation: pulseopacity 0.9s ease infinite;
      display: block;
      background: #4a140e;
      position: relative;
      margin-right: 5px;
    }
  }

  .distribution-item-controls-timer {
    position: absolute;
    bottom: 4px;
    right: 4px;
    color: rgba(230, 155, 255, 0.4);

    .icon {
      color: #FEF2C8;
      position: relative;
      text-shadow: -1px -1px 0 #FF2C3B, 1px -1px 0 #FF2C3B, -1px 1px 0 #FF2C3B,
      1px 1px 0 #FF2C3B, 0px 0px 12px #FF2F2F;
      font-size: 16px;
      background: none;
      box-shadow: none;
    }
  }

  &-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 64px;
    height: 64px;

    .icon {
      font-size: 64px;
      line-height: 64px;
      color: #F7DBFF;
      text-shadow: -2px -2px 0 #D32CE6, 2px -2px 0 #D32CE6, -2px 2px 0 #D32CE6,
      2px 2px 0 #D32CE6, 0px 0px 32px #D32CE6;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
  }

  &:hover {
    .stream-play {
      .icon {
        color: #F1C0FF;
        transform: translate(-50%, -50%) scale(1.062);
      }
    }
  }

  .close {
    position: absolute;
    top: -8px;
    right: -8px;
    color: rgba(211, 44, 230, 0.4);
    background: linear-gradient(90deg, #1C1132 0%, #120929 100%);
    border: 2px solid rgba(211, 44, 230, 0.4);
    box-shadow: 0px 0px 28px rgba(211, 44, 230, 0.4);
    border-radius: 24px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: 99;
  }

  &:hover {
    .close {
      opacity: 1;
      visibility: visible;
    }
  }

  &.toggle {
    height: 32px;
    border-radius: 16px 0 0 16px;
    right: 0;
    box-shadow: inset 0 0 0 4px rgb(211 44 230 / 40%);
    transform: translateX(calc(100% - 32px));

    iframe {
      height: 100%;
    }

    .stream-play {
      width: 24px;
      height: 24px;
      top: 4px;
      left: 4px;
      transform: translate(0, 0);
      z-index: 99;

      .icon {
        transform: translate(-50%, -50%) scale(0.375);
      }
    }

    .distribution-item-controls-timer {
      display: none;
    }

    .stream-online {
      left: 32px;
    }
  }
}
