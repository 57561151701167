@import '../mixins/mixins';

.mob-menu-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  display: none;
  background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%), #13072C;
  border-top: 2px solid rgba(252, 221, 255, 0.16);
  z-index: 99999;
  padding: 0 20px;

  @media(max-width: 992px) {
    display: flex;
  }
  &__nav {
    padding-top: 4px;
    margin-top: -2px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    @include flex(false, center, space-between, false);
  }
  &__nav-item {
    width: 48px;
    height: 100%;
    position: relative;
    text-decoration: none;
    @include flex(false, center, center, false);

    .icon {
      color: #FFC600;
    }
    @include after() {
      width: 96px;
      height: 96px;
      left: calc(50% - 96px/2);
      background: radial-gradient(50% 100% at 50% 100%, #D32CE6 0%, rgba(29, 5, 56, 0.0001) 100%);
      mix-blend-mode: normal;
      opacity: 0;
      transition: opacity .3s;
    }
    &:hover {
      opacity: .2;

      .icon {
        color: #FFDF77;
      }
    }
    &.active {
      .icon {
        color: #D32CE6;
      }
      @include before() {
        background: #D32CE6;
        width: 8px;
        height: 8px;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
      }
      &:after {
        opacity: .2;
      }
    }
  }
}