.main:not([data-pathname='/pay']) {
  .Toastify {
    .absolute {
      position: absolute;
      margin-top: 250px;
    }
  }
}

.Toastify {
  position: relative;
  overflow-x: clip;

  @media(max-width: 992px) {
    display: none !important;
  }
  .Toastify__toast-container {
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 120px;

    @media(max-width: 480px) {
      width: calc(100% - 20px);
      left: 10px;
    }
  }
  .Toastify__toast--default,
  .Toastify__toast--success {
    background-image: linear-gradient(
      to bottom,
      rgba(211, 44, 230, 0.12),
      rgba(211, 44, 230, 0.5)
    );
    box-shadow: 0 0 42px #d32ce6, inset 0 0 28px #d32ce6, inset 0 0 4px #d32ce6;
    border: 3px solid #d32ce6;
    padding: 0 !important;
    border-radius: 26px !important;

    &:after {
      content: '';
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      border: 3px solid #d32ce6;
      z-index: 2;
      border-radius: 18px;
      position: absolute;
      top: 4px;
      left: 4px;
      box-shadow: inset 0 0 18px #d32ce6, inset 0 0 4px #d32ce6;
      pointer-events: none;
    }
    .Toastify__toast-body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(211, 44, 230, 0.34);
      padding: 11px 16px 10px 16px !important;
      margin: 0;
    }
    .notify-title {
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
        1px 1px 0 #d32ce6, 0 0 24px #d32ce6, 0 0 13px #d32ce6;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 24px;
      color: #fff;
      text-transform: uppercase;
    }
    .notify-text {
      font-size: 14px;
      line-height: 20px;
      color: #d6ddff;
      border: 1px solid rgba(211, 44, 230, 0);
      opacity: 0.6;
      text-shadow: 0px 0px 32px #4b69ff;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notify-btn {
      display: inline-flex;
      align-items: center;
      border: 2px solid #fff;
      border-radius: 48px;
      min-width: auto;
      padding-right: 20px;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #d32ce6;
      background: rgba(211, 44, 230, 0.08);
      box-shadow: 0px 0px 8px #d32ce6, inset 0px 0px 8px #d32ce6;

      .material-icons {
        font-weight: normal;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        color: #d32ce6;
        padding: 0 8px;
        background: none;
      }
      &:hover {
        color: #f7c0fe;
        background: rgba(211, 44, 230, 0.36);

        .material-icons {
          color: #f7c0fe;
        }
      }
    }
  }
  .Toastify__toast--error {
    background-image: linear-gradient(
      to bottom,
      rgba(235, 75, 75, 0.12),
      rgba(235, 75, 75, 0.5)
    );
    box-shadow: 0 0 42px #eb4b4b, inset 0 0 28px #eb4b4b, inset 0 0 4px #eb4b4b;
    border: 3px solid #eb4b4b;
    padding: 0 !important;
    border-radius: 26px !important;

    &:after {
      content: '';
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      border: 3px solid #eb4b4b;
      z-index: 2;
      border-radius: 18px;
      position: absolute;
      top: 4px;
      left: 4px;
      box-shadow: inset 0 0 18px #eb4b4b, inset 0 0 4px #eb4b4b;
      pointer-events: none;
    }
    .Toastify__toast-body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(212, 45, 230, 0.34);
      padding: 15px 16px 10px 16px !important;
      margin: 0;
    }
    .noty_body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(235, 75, 75, 0.34);
      padding: 12px 16px 10px 16px !important;
    }
    .notify-title {
      text-shadow: -1px -1px 0 #eb4b4b, 1px -1px 0 #eb4b4b, -1px 1px 0 #eb4b4b,
        1px 1px 0 #eb4b4b, 0 0 24px #eb4b4b, 0 0 13px #eb4b4b;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-transform: uppercase;
      color: #fff !important;
    }
    .notify-text {
      font-size: 14px;
      line-height: 20px;
      color: #d6ddff;
      border: 1px solid rgba(211, 44, 230, 0);
      opacity: 0.6;
      text-shadow: 0px 0px 32px rgba(75, 105, 255, 0.5);
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notify-btn {
      display: inline-flex;
      align-items: center;
      border: 2px solid #fff;
      border-radius: 48px;
      min-width: auto;
      padding-right: 20px;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #d32ce6;
      background: rgba(211, 44, 230, 0.08);
      box-shadow: 0px 0px 8px #d32ce6, inset 0px 0px 8px #d32ce6;

      .material-icons {
        font-weight: normal;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        color: #d32ce6;
        padding: 0 8px;
        background: none;
      }
      &:hover {
        color: #f7c0fe;
        background: rgba(211, 44, 230, 0.36);

        .material-icons {
          color: #f7c0fe;
        }
      }
    }
  }
  .Toastify__toast--warning {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 198, 0, 0.12),
      rgba(255, 198, 0, 0.5)
    );
    box-shadow: 0 0 42px #ffc600, inset 0 0 28px #ffc600, inset 0 0 4px #ffc600;
    border: 3px solid #ffc600;
    padding: 0 !important;
    border-radius: 26px !important;

    &:after {
      content: '';
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      border: 3px solid #ffc600;
      z-index: 2;
      border-radius: 18px;
      position: absolute;
      top: 4px;
      left: 4px;
      box-shadow: inset 0 0 18px #ffc600, inset 0 0 4px #ffc600;
      pointer-events: none;
    }
    .Toastify__toast-body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(255, 198, 0, 0.34);
      padding: 15px 16px 10px 16px !important;
      margin: 0;
    }
    .noty_body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(255, 198, 0, 0.34);
      padding: 12px 16px 10px 16px !important;
    }
    .notify-title {
      text-shadow: -1px -1px 0 #ffc600, 1px -1px 0 #ffc600, -1px 1px 0 #ffc600,
        1px 1px 0 #ffc600, 0 0 24px #ffc600, 0 0 13px #ffc600;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-transform: uppercase;
      color: #fff !important;
    }
    .notify-text {
      font-size: 14px;
      line-height: 20px;
      color: #d6ddff;
      border: 1px solid rgba(211, 44, 230, 0);
      opacity: 0.6;
      text-shadow: 0px 0px 32px rgba(255, 198, 0, 0.5);
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notify-btn {
      display: inline-flex;
      align-items: center;
      border: 2px solid #fff;
      border-radius: 48px;
      min-width: auto;
      padding-right: 20px;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #ffc600;
      background: rgba(75, 105, 255, 0.08);
      box-shadow: 0px 0px 32px #ffc600, inset 0px 0px 32px #ffc600,
        0px 0px 8px #ffc600, inset 0px 0px 8px #ffc600;

      .material-icons {
        font-weight: normal;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        color: #ffc600;
        padding: 0 8px;
        background: none;
      }
      &:hover {
        color: #b4c0ff;
        background: rgba(255, 198, 0, 0.36);

        .material-icons {
          color: #b4c0ff;
        }
      }
    }
  }
  .Toastify__toast--info {
    background-image: linear-gradient(
      to bottom,
      rgba(75, 105, 255, 0.12),
      rgba(75, 105, 255, 0.5)
    );
    box-shadow: 0 0 42px #4b69ff, inset 0 0 28px #4b69ff, inset 0 0 4px #4b69ff;
    border: 3px solid #4b69ff;
    padding: 0 !important;
    border-radius: 26px !important;

    &:after {
      content: '';
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      border: 3px solid #4b69ff;
      z-index: 2;
      border-radius: 18px;
      position: absolute;
      top: 4px;
      left: 4px;
      box-shadow: inset 0 0 18px #4b69ff, inset 0 0 4px #4b69ff;
      pointer-events: none;
    }
    .Toastify__toast-body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(75, 105, 255, 0.34);
      padding: 15px 16px 10px 16px !important;
      margin: 0;
    }
    .noty_body {
      border: 4px solid #fff;
      border-radius: 24px;
      background: #250229;
      box-shadow: 0px 3px 64px rgba(75, 105, 255, 0.34);
      padding: 12px 16px 10px 16px !important;
    }
    .notify-title {
      text-shadow: -1px -1px 0 #4b69ff, 1px -1px 0 #4b69ff, -1px 1px 0 #4b69ff,
        1px 1px 0 #4b69ff, 0 0 24px #4b69ff, 0 0 13px #4b69ff;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-transform: uppercase;
      color: #fff !important;
    }
    .notify-text {
      font-size: 14px;
      line-height: 20px;
      color: #d6ddff;
      border: 1px solid rgba(211, 44, 230, 0);
      opacity: 0.6;
      text-shadow: 0px 0px 32px rgba(75, 105, 255, 0.5);
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notify-btn {
      display: inline-flex;
      align-items: center;
      border: 2px solid #fff;
      border-radius: 48px;
      min-width: auto;
      padding-right: 20px;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #4b69ff;
      background: rgba(75, 105, 255, 0.08);
      box-shadow: 0px 0px 32px #4b69ff, inset 0px 0px 32px #4b69ff,
        0px 0px 8px #4b69ff, inset 0px 0px 8px #4b69ff;

      .material-icons {
        font-weight: normal;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        color: #4b69ff;
        padding: 0 8px;
        background: none;
      }
      &:hover {
        color: #b4c0ff;
        background: rgba(75, 105, 255, 0.36);

        .material-icons {
          color: #b4c0ff;
        }
      }
    }
  }
}
.Toastify__close-button {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 0.2;
}
.Toastify__close-button > svg {
  width: 25px;
  height: 25px;
}
.Toastify--animate-icon,
.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify .Toastify__toast--default,
.Toastify .Toastify__toast--success {
  &.notify-sell-product {
    background: linear-gradient(
      133.83deg,
      #0d0329 1.96%,
      #250055 51.47%,
      #77007b 100%
    );
    box-shadow: 0 0 0 2px rgba(136, 71, 255, 0.16), 0px 20px 28px #13072c,
      0px 28px 64px rgba(211, 44, 230, 0.2);
    border-radius: 20px !important;
    overflow: visible;
    padding: 12px 16px 12px 12px !important;
    border: 0;

    @media(max-width: 480px) {
      width: 100%;
    }
    &:after {
      display: none;
    }
    .Toastify__toast-body {
      border-radius: 24px;
      margin: 0;
      padding: 0 !important;
      border: 0;
      background: none;
      box-shadow: none;
    }
    .notify-product {
      display: flex;

      &-content {
        display: flex;
        flex-direction: column;
        min-width: 190px;
        padding-right: 30px;
        max-width: 210px;
      }
      .top-drop-item {
        margin-right: 16px;

        @media(max-width: 359px) {
          min-width: 108px;
          width: 108px;
        }
        .in-case-cost {
          padding: 2px 8px;
          top: 0;
        }
        &:hover {
          .top-drop-item-hover {
            padding-top: 73px;
          }
        }
      }
      .drop-img {
        width: 100%;
        height: 62px;
        position: relative;
        z-index: 3;
        transition: all 0.3s;

        img {
          width: 92px;
          height: 72px;
          object-fit: contain;
          display: block;
          margin: 0 auto 0 auto;
        }
      }
    }
    .notify-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #f7bffe;
      text-shadow: 0px 0px 16px #d32ce6;
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
        1px 1px 0 #d32ce6, 0px 0px 16px #d32ce6;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .notify-text {
      font-size: 14px;
      line-height: 21px;
      border: 1px solid rgba(211, 44, 230, 0);
      text-shadow: 0px 0px 32px #4b69ff;
      color: rgba(230, 155, 255, 0.8);
      margin: 0;
    }
    .notify-price {
      font-size: 14px;
      line-height: 21px;
      color: rgba(213, 185, 255, 0.5);
    }
    .close-button {
      position: absolute;
      z-index: 2;
      top: -24px;
      right: 0;
      color: #fff;
      background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
      box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.4),
        0px 0px 28px rgba(136, 71, 255, 0.4);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: rgba(136, 71, 255, 0.4);
      transition: all 0.3s;
      font-size: 30px;
      line-height: 30px;
      transition: all 0.3s;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(136, 71, 255, 0.2),
            rgba(136, 71, 255, 0.2)
          ),
          linear-gradient(90deg, #1c1132 0%, #120929 100%);
        box-shadow: inset 0 0 0 4px #8847ff,
          0px 0px 28px rgba(136, 71, 255, 0.4);
        transform: rotate(180deg);
        .icon {
          color: #8847ff;
        }
      }

      &:active {
        background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
        box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.4),
          0px 0px 28px rgba(136, 71, 255, 0.4);
        transform: rotate(180deg);
        .icon {
          color: rgba(136, 71, 255, 0.4);
        }
      }
    }
  }
}

.Toastify__toast {
  margin-bottom: 24px;
  position: relative;
}

.Toastify .Toastify__toast--success.notify-sell-product {
  .notify-quest {
    min-width: 382px;
    padding: 4px 0 4px 4px;

    @media(max-width: 440px) {
      min-width: 100%;
    }

    .notify-product-content {
      max-width: initial;
      padding-right: 0;
    }
  }
}

.notify-quest-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .notify-text {
    font-size: 16px;
    line-height: 16px;
    color: rgba(230, 155, 255, 0.8);
  }

  .notify-quest-price {
    display: flex;
    margin-left: 16px;
    height: 24px;
    background: linear-gradient(90deg, rgba(0, 87, 0, 0.2) 0%, rgba(8, 148, 8, 0.2) 100%);
    border: 2px solid rgba(6, 197, 12, 0.4);
    border-radius: 12px;
    padding: 2px 8px 2px 5px;

    span {
      margin-right: 4px;
      white-space: nowrap;
    }
  }
}

.notify-btn-go-to {
  height: 36px;
  background: linear-gradient(90deg, #380B9D 0%, #490475 100%);
  border: 2px solid #D32CE6;
  border-radius: 18px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.56);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: linear-gradient(90deg, #490475 0%, #380B9D 100%);
  }
}