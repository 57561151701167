.loaderFon {
  color: $white;
  background-attachment: fixed;
  width: 64px;
  height: 64px;
  top: 30%;
  left: 50%;
  position: absolute;
  font-size: 32px;
  margin-left: -32px;
}

.upgrade-items-content {
  .loaderFon {
    top: 45%;
  }
}

.cases.cases-all .row .loader {
  position: relative;
  margin-bottom: 150px;
}

.infinite-scroll-component__outerdiv {
  .loaderFon {
    position: relative;
    top: 0;
  }
}

.infinite-scroll-component {
  overflow-y: hidden;
}

.loader {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  height: 64px;
  padding: 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-item {
    background: url(../images/loader-bg.svg) no-repeat 50% 100%;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    background-size: 100% 100%;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: url(../images/loader-border.svg) no-repeat 50% 50%;
      display: block;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: url(../images/loader-spin.svg) no-repeat 50% 50%;
      display: block;
      width: 100%;
      height: 100%;
      animation: spinAnimate 3s linear infinite;
      background-size: 100% 100%;
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: url(../images/loader-bg-2.svg) no-repeat 50% 50%;
      display: block;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }

    // &-logo {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -120%);
    //   background: url(../images/loader-logo.png) no-repeat 50% 50%;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 4;
    //   animation-duration: 4s;
    //   animation-name: logoAnimate;
    //   animation-iteration-count: infinite;
    //   background-size: 100% 100%;
    // }
  }
}

@keyframes logoAnimate {
  0% {
    transform: translate(-50%, -120%);
    animation-play-state: running;
  }
  30% {
    transform: translate(-50%, -50%);
    animation-play-state: paused;
  }
  50% {
    transform: translate(-50%, -50%);
    animation-play-state: paused;
  }
  70% {
    animation-play-state: paused;
  }
  100% {
    transform: translate(-50%, 100%);
    animation-play-state: running;
  }
}

@keyframes spinAnimate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
